import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import Details from './Details'
import Edit from './Edit'

function PaymentMethod(props) {
  return (
    <Grid container spacing={2} justify='center' alignItems='center'>
      {props.editing ? (
        <Edit
          error={props.error}
          onUpdate={props.onUpdate}
          onCancel={props.onCancel}
          updating={props.updating}
        />
      ) : (
        <Details
          card={props.card}
          isActive={props.isActive}
          onEdit={props.onEdit}
        />
      )}
    </Grid>
  )
}

PaymentMethod.propTypes = {
  card: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
}

export default PaymentMethod
