import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { Form as FormikForm, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 600,
  },
  header: {
    marginTop: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  fill: {
    width: '100%',
  },
  centerVertical: {
    display: 'flex',
    alignItems: 'center',
  },
}))

function InviteMemberForm(props) {
  const { onSubmit } = props
  const classes = useStyles()

  // Fix label on role select field
  const inputLabel = React.useRef()
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const initial = {
    email: '',
    role: 'member',
  }

  const validate = values => {
    const errors = {}
    if (!values.email.match(/.+@.+/g)) {
      errors.email = 'Please enter an email address'
    }
    return errors
  }

  const submit = async (values, actions) => {
    try {
      await onSubmit(values)
    } catch (err) {
      console.error('Error updating policy')
      console.error(err)
    }
    actions.setSubmitting(false)
    actions.resetForm()
  }

  return (
    <Formik initialValues={initial} onSubmit={submit} validate={validate}>
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        handleReset,
        isSubmitting,
        isValid,
        setFieldTouched,
        setFieldValue,
        touched,
        values,
      }) => (
        <FormikForm>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant='outlined'
                margin='dense'
                id='email'
                name='email'
                label='email'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.email && !!errors.email}
                helperText={touched.email && !!errors.email && errors.email}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                variant='outlined'
                margin='dense'
                className={classes.fill}
              >
                <InputLabel ref={inputLabel} id='role_label'>
                  Role
                </InputLabel>
                <Select
                  id='role'
                  name='role'
                  labelid='role_label'
                  value={values.role}
                  onChange={handleChange}
                  labelWidth={labelWidth}
                >
                  {['member', 'admin'].map(role => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2} className={classes.centerVertical}>
              <Button
                type='submit'
                disabled={!dirty || !isValid || isSubmitting}
                variant='contained'
                color={dirty ? 'primary' : 'secondary'}
              >
                {isSubmitting ? 'Inviting' : 'Invite'}
              </Button>
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  )
}

InviteMemberForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default InviteMemberForm
