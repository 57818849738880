import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Form as FormikForm, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 600,
    margin: `calc(${theme.spacing(5)}px * 2) auto 0`,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  input: {
    margin: theme.spacing(2, 0),
  },
}))

function SetupForm(props) {
  const classes = useStyles()
  const initial = {
    name: '',
  }

  const validate = values => {
    let errors = {}
    if (!values.name) {
      errors.name = 'Please enter a name for your organization'
    }
    return errors
  }

  const submit = async (values, actions) => {
    try {
      await props.onSubmit(values)
    } catch (err) {
      console.error('Error creating organization')
      console.error(err)
    }
    actions.setSubmitting(false)
  }

  return (
    <Formik initialValues={initial} onSubmit={submit} validate={validate}>
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
      }) => (
        <FormikForm>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant='h5' component='h2' gutterBottom>
                Welcome to Cert Comply
              </Typography>
              <Typography variant='body1' gutterBottom>
                To get started, tell us the name of your organization.
              </Typography>
              <TextField
                className={classes.input}
                fullWidth
                id='name'
                name='name'
                label='Organization Name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.name}
                helperText={!!errors.name ? errors.name : ''}
              />
            </CardContent>
            <CardActions>
              <Button
                type='submit'
                disabled={!values.name || isSubmitting}
                variant={!!values.name ? 'contained' : null}
                color={!!values.name ? 'primary' : 'secondary'}
              >
                Submit
              </Button>
            </CardActions>
          </Card>
        </FormikForm>
      )}
    </Formik>
  )
}

SetupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default SetupForm
