import { Container } from '@material-ui/core'
import { editPolicy } from 'actions/policies'
import EditPolicyForm from 'components/Forms/EditPolicy'
import Navigation from 'components/Navigation'
import ScreenTitle from 'components/ScreenTitle'
import routes, { formatRoute } from 'config/routes'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

function Edit({
  editPolicy,
  currentOrganization,
  subcontractor,
  policy,
  ...props
}) {
  const [complete, setComplete] = useState()

  const submit = async (values) => {
    try {
      await editPolicy(
        policy.id,
        subcontractor.id,
        currentOrganization.id,
        values
      )
      setComplete(true)
    } catch (err) {
      throw err
    }
  }

  const cancel = () => {
    props.history.goBack()
  }

  if (!policy || complete) {
    const route = formatRoute(routes.subcontractor, {
      subId: subcontractor.id,
    })
    return <Redirect to={route} />
  }

  return (
    <Navigation>
      <Container>
        <ScreenTitle>Edit Policy</ScreenTitle>
        <EditPolicyForm
          onSubmit={submit}
          onCancel={cancel}
          policy={policy}
          subcontractor={subcontractor}
        />
      </Container>
    </Navigation>
  )
}

Edit.propTypes = {
  editPolicy: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  policy: PropTypes.object.isRequired,
  subcontractor: PropTypes.object.isRequired,
}

const mapState = (state) => ({
  currentOrganization: state.app.currentOrganization,
})

const mapDispatch = {
  editPolicy,
}

export default connect(mapState, mapDispatch)(Edit)
