import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Link, makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { formatNumber } from 'utils'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  addressContainer: {
    display: 'flex',
  },
}))

const Address = (props) => (
  <div>
    {props.company_address_street_1}
    {props.company_address_street_1 ? <br /> : null}
    {props.company_address_street_2}
    {props.company_address_street_2 ? <br /> : null}
    {props.company_address_city}
    {props.company_address_city ? ', ' : null}
    {props.company_address_state}
    {props.company_address_state ? ' ' : null}
    {props.company_address_zipcode}
  </div>
)

function Details(props) {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={12}>
        <Typography variant='subtitle1'>
          <FontAwesomeIcon
            icon='phone'
            className={`fa-fw fa-sm ${classes.icon}`}
          />
          {formatNumber(props.company_phone)}
        </Typography>
        <Typography variant='subtitle1'>
          <FontAwesomeIcon
            icon='fax'
            className={`fa-fw fa-sm ${classes.icon}`}
          />
          {formatNumber(props.company_fax)}
        </Typography>
        <div className={`${classes.addressContainer} ${classes.infoItem}`}>
          <Typography variant='subtitle1'>
            <FontAwesomeIcon
              icon='building'
              className={`fa-fw fa-sm ${classes.icon}`}
            />
          </Typography>
          <Typography variant='subtitle1'>
            <Address {...props} />
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={12}>
        <Typography variant='h6'>Primary Contact</Typography>
        <Typography variant='subtitle1'>
          <FontAwesomeIcon
            icon='envelope'
            className={`fa-fw fa-sm ${classes.icon}`}
          />
          <Link
            color='secondary'
            href={`mailto:${props.primary_contact_email}`}
            target='_blank'
          >
            {props.primary_contact_email}
          </Link>
          {/* <a href={`mailto:${primary_contact_email}`}>{primary_contact_email}</a> */}
        </Typography>
        <Typography variant='subtitle1'>
          <FontAwesomeIcon
            icon='user'
            className={`fa-fw fa-sm ${classes.icon}`}
          />
          {props.primary_contact_first_name}&nbsp;
          {props.primary_contact_last_name}
        </Typography>
        <Typography variant='subtitle1'>
          <FontAwesomeIcon
            icon='phone'
            className={`fa-fw fa-sm ${classes.icon}`}
          />
          {formatNumber(props.primary_contact_phone)}
        </Typography>
      </Grid>
    </Grid>
  )
}

Details.propTypes = {
  company_fax: PropTypes.string,
  company_phone: PropTypes.string,
  company_address_street_1: PropTypes.string,
  company_address_street_2: PropTypes.string,
  company_address_city: PropTypes.string,
  company_address_state: PropTypes.string,
  company_address_zipcode: PropTypes.string,
  primary_contact_email: PropTypes.string.isRequired,
  primary_contact_first_name: PropTypes.string,
  primary_contact_last_name: PropTypes.string,
  primary_contact_phone: PropTypes.string,
}

export default Details
