// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "cert-comply-20191101212125-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d3fjzchwiv2qqq.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-west-2:ccaaadf9-6ac0-4643-bf68-44d249c33c44",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_3DzZP90Eg",
    "aws_user_pools_web_client_id": "31fp2ihjcf3u5u7d1d37lvm7j9",
    "oauth": {}
};


export default awsmobile;
