import { SET_LOADING } from 'actions'
import { SET_CURRENT_ORGANIZATION } from 'actions/organizations'

const initialState = {
  loading: true,
  currentOrganization: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case SET_CURRENT_ORGANIZATION:
      return {
        ...state,
        currentOrganization: action.organization || state.currentOrganization,
      }
    default:
      return state
  }
}
