import {
  Button,
  Card,
  CardContent,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { acceptInvite, getInviteDetails } from 'actions'
import LoadingContainer from 'components/LoadingContainer'
import Navigation from 'components/Navigation'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 600,
    margin: `calc(${theme.spacing(5)}px * 2) auto 0`,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}))

function Join(props) {
  const [loading, setLoading] = React.useState(true)
  const [code, setCode] = React.useState()
  const [details, setDetails] = React.useState({})
  const [error, setError] = React.useState(false)
  const classes = useStyles()

  React.useEffect(() => {
    const load = async () => {
      try {
        const params = new URLSearchParams(window.location.search)
        const resp = await props.getInviteDetails(params.get('code'))
        setCode(params.get('code'))
        setDetails(resp)
      } catch (err) {
        setError(true)
        console.error('Unable to get code details')
        console.error(err)
      }
      setLoading(false)
    }
    load()
    // eslint-disable-next-line
  }, [])

  const accept = async () => {
    try {
      await props.acceptInvite(code)
      props.history.push(routes.dashboard)
    } catch (err) {
      console.error('Unable to accept invite')
      console.error(err)
    }
  }

  return (
    <Navigation>
      <Container>
        <LoadingContainer loading={loading}>
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              {error ? (
                <Typography variant='body1' gutterBottom>
                  The code you provided has already been used or is invalid.
                </Typography>
              ) : (
                <>
                  <Typography variant='h5' component='h2' gutterBottom>
                    You've been invited to join{' '}
                    <strong>{details.organization}</strong>
                  </Typography>
                  <Typography variant='body1'>
                    role: <strong>{details.role}</strong>
                  </Typography>
                  <Button
                    color='primary'
                    variant='contained'
                    className={classes.button}
                    onClick={accept}
                  >
                    Accept
                  </Button>
                </>
              )}
            </CardContent>
          </Card>
        </LoadingContainer>
      </Container>
    </Navigation>
  )
}

Join.propTypes = {
  acceptInvite: PropTypes.func.isRequired,
  getInviteDetails: PropTypes.func.isRequired,
}

const mapDispatch = {
  acceptInvite,
  getInviteDetails,
}

export default connect(null, mapDispatch)(Join)
