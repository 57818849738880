import { Auth } from 'aws-amplify'
import api, { urls } from 'config/api'

/**
 * Action types
 */
export const SET_AUTH = 'SET_AUTH'
export const SET_ROLE = 'SET_ROLE'
export const SET_EMAIL_SETTINGS = 'SET_EMAIL_SETTINGS'

/**
 * Action creators
 */
const _setAuth = userAttributes => ({ type: SET_AUTH, userAttributes })
const _setRole = role => ({ type: SET_ROLE, role })
const _setEmailSettings = settings => ({ type: SET_EMAIL_SETTINGS, settings })

/**
 * Actions
 */
export const login = () => async dispatch => {
  try {
    const { attributes } = await Auth.currentUserInfo()
    dispatch(_setAuth(attributes))
    await dispatch(getUserEmailSettings())
  } catch (err) {
    console.error('Error getting user details')
    console.error(err)
    // Ask the user to log in again
    dispatch(logout())
  }
}

export const logout = () => async dispatch => {
  try {
    Auth.signOut()
  } catch (err) {
    // no-op:
    console.error('Error logging out')
    console.error(err)
  }
}

export const getUserRole = orgId => async dispatch => {
  try {
    const resp = await api.get(urls.userRole({ orgId }))
    dispatch(_setRole(resp.data.role))
  } catch (err) {
    console.error('Unable to get user role')
    console.error(err)
  }
}

export const updateUserAttributes = attributes => async dispatch => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, attributes)
    const { attributes: updatedAttributes } = await Auth.currentUserInfo()
    dispatch(_setAuth(updatedAttributes))
  } catch (err) {
    throw err
  }
}

export const getUserEmailSettings = () => async dispatch => {
  const resp = await api.get(urls.user())
  await dispatch(_setEmailSettings(resp.data))
}

export const updateUserEmailSettings = (settings) => async dispatch => {
  const resp = await api.put(urls.user(), {
    ...settings,
  })
  await dispatch(_setEmailSettings(resp.data))
}
