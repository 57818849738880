import { ADD_INVITE, DELETE_INVITE, SET_INVITES } from 'actions/invites'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INVITES:
      return [...action.invites]
    case ADD_INVITE:
      return [...state, action.invite]
    case DELETE_INVITE:
      return state.filter(i => i.id !== action.id)
    default:
      return state
  }
}
