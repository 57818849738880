import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core'
import DefaultTable from 'components/Tables/Default'
import routes, { formatRoute } from 'config/routes'
import PropTypes from 'prop-types'
import React from 'react'
import { renderLink } from 'utils'

const useStyles = makeStyles((theme) => ({
  textCenter: {
    textAlign: 'center',
  },
  bodyNoOrgs: {
    padding: theme.spacing(3, 0),
  },
}))

const RowSelect = (selected, setSelected) => ({ cell: { value } }) => {
  const checked = selected.includes(value)
  const onChange = () => {
    checked
      ? setSelected(selected.filter((id) => id !== value))
      : setSelected([...selected, value])
  }
  return <Checkbox checked={checked} onChange={onChange} />
}

const BtnCell = ({ cell: { value }, row }) => {
  const route = formatRoute(routes.subcontractor, { subId: row.original.id })
  return (
    <Link variant='button' color='secondary' component={renderLink(route)}>
      {value}
    </Link>
  )
}

const buildContactName = (first, last) => {
  const names = []
  if (!!first) names.push(first)
  if (!!last) names.push(last)
  return names.join(' ')
}

const SubcontractorsTable = ({ subcontractors, selected, setSelected }) => {
  const classes = useStyles()
  const someSelected = selected.length > 0
  const allSelected = selected.length === subcontractors.length
  const handleSelectAll = React.useCallback(() => {
    allSelected ? setSelected([]) : setSelected(subcontractors.map((s) => s.id))
  }, [allSelected, setSelected, subcontractors])

  const data = React.useMemo(() => subcontractors, [subcontractors])
  const columns = React.useMemo(
    () => [
      {
        Header: (
          <Checkbox
            checked={someSelected}
            indeterminate={someSelected && !allSelected}
            onChange={handleSelectAll}
          />
        ),
        Cell: RowSelect(selected, setSelected),
        accessor: 'id',
      },
      {
        Header: 'NAME',
        Cell: BtnCell,
        accessor: 'company_name',
      },
      {
        Header: 'PRIMARY CONTACT EMAIL',
        accessor: 'primary_contact_email',
      },
      {
        Header: 'PRIMARY CONTACT NAME',
        accessor: (row) =>
          buildContactName(
            row.primary_contact_first_name,
            row.primary_contact_last_name
          ),
      },
      {
        Header: 'ACTIVE',
        accessor: (row) => (row.active ? 'Yes' : 'No'),
      },
    ],
    [selected, setSelected, someSelected, allSelected, handleSelectAll]
  )

  return (
    <Card>
      <CardContent>
        {subcontractors.length === 0 ? (
          <div className={classes.textCenter}>
            <Typography variant='body1' className={classes.bodyNoOrgs}>
              Your organization doesn't have any subcontractors
            </Typography>
            <Button
              component={renderLink(routes.subcontractorNew)}
              variant='contained'
              color='primary'
              startIcon={<FontAwesomeIcon icon='plus' className={`fa-fw`} />}
            >
              Add Subcontractor
            </Button>
          </div>
        ) : (
          <DefaultTable columns={columns} data={data} />
        )}
      </CardContent>
    </Card>
  )
}

SubcontractorsTable.propTypes = {
  subcontractors: PropTypes.array.isRequired,
}

export default SubcontractorsTable
