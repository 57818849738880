import { Chip, makeStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { toTitleCase } from 'utils'

const useStyles = makeStyles((theme) => ({
  generalType: {
    backgroundColor: theme.palette.success.main,
  },
  workType: {
    margin: theme.spacing(0.5),
    color: 'white',
    fontWeight: 'bold',
  },
}))

function WorkTypes(props) {
  const classes = useStyles()

  return (
    <div>
      <div className='general-types'>
        {props.residential ? (
          <Chip
            label='Residential'
            className={[classes.workType, classes.generalType].join(' ')}
          />
        ) : null}
        {props.commercial ? (
          <Chip
            label='Commercial'
            className={[classes.workType, classes.generalType].join(' ')}
          />
        ) : null}
      </div>
      <div className='contract-types'>
        {props.contract_types.map((type) => (
          <Chip
            key={type}
            color='secondary'
            label={toTitleCase(type)}
            className={classes.workType}
          />
        ))}
      </div>
    </div>
  )
}

WorkTypes.propTypes = {
  residential: PropTypes.bool.isRequired,
  commercial: PropTypes.bool.isRequired,
  contract_types: PropTypes.array.isRequired,
}

export default WorkTypes
