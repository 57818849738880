import { Container } from '@material-ui/core'
import { Elements } from '@stripe/react-stripe-js'
import { getSubscription } from 'actions/subscription'
import LoadingContainer from 'components/LoadingContainer'
import Navigation from 'components/Navigation'
import ScreenTitle from 'components/ScreenTitle'
import routes from 'config/routes'
import stripe from 'config/stripe'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import Details from './Details'
import New from './New'

function Subscription(props) {
  const { getSubscription, subscription, currentOrganization } = props
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const load = async () => {
      await getSubscription(currentOrganization.id)
      setLoading(false)
    }

    if (!!currentOrganization) {
      load()
    }
  }, [getSubscription, setLoading, currentOrganization])

  return (
    <Elements stripe={stripe}>
      <Navigation>
        <Container>
          <ScreenTitle>Subscription</ScreenTitle>
          <LoadingContainer loading={loading}>
            <Switch>
              <Route
                exact
                path={routes.subscription}
                render={(props) => (
                  <Details {...props} subscription={subscription} />
                )}
              />
              <Route exact path={routes.subscriptionNew} component={New} />
            </Switch>
          </LoadingContainer>
        </Container>
      </Navigation>
    </Elements>
  )
}

Subscription.propTypes = {
  currentOrganization: PropTypes.object,
  getSubscription: PropTypes.func.isRequired,
  subscription: PropTypes.object,
}

const mapState = (state) => ({
  currentOrganization: state.app.currentOrganization,
  subscription: state.subscription,
})

const mapDispatch = {
  getSubscription,
}

export default connect(mapState, mapDispatch)(Subscription)
