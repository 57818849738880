/**
 * tokenUpload.js
 *
 * Actions here are all for subcontractors that are uploading their own
 * documentation.  They receive an email when they are first added to
 * the system as well as anytime policies need updating and they use
 * a token for authentication.
 */
import api, { urls } from 'config/api'

/**
 * Actions
 */
export const getSubcontractor = (token) => async () => {
  const resp = await api.get(urls.tokenSubcontractor(), {
    headers: {
      Authorization: `token ${token}`,
    },
  })
  return resp.data
}

export const updateSubcontractor = (token, values) => async () => {
  const resp = await api.put(
    urls.tokenSubcontractor(),
    { ...values },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  )
  return resp.data
}

export const getDocument = (token, id) => async () => {
  const resp = await api.get(urls.tokenSubcontractorDocumentDetail({ id }), {
    headers: {
      Authorization: `token ${token}`,
    },
  })
  return resp.data
}

export const uploadDocument = (token, values) => async () => {
  const resp = await api.post(
    urls.tokenSubcontractorDocumentUpload(),
    { ...values },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  )
  return resp.data
}

export const uploadPolicy = (token, values) => async () => {
  const resp = await api.post(
    urls.tokenSubcontractorPolicy(),
    { ...values },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  )
  return resp.data
}
