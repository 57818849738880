import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles, Typography } from '@material-ui/core'
import { CardElement as StripeCardElement } from '@stripe/react-stripe-js'
import { useCommonStyles } from 'hooks'
import PropTypes from 'prop-types'
import React from 'react'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
  },
}

const useStyles = makeStyles((theme) => ({
  cardElement: {
    border: '1px solid lightgray',
    borderRadius: 5,
    padding: theme.spacing(1),
  },
}))

function CardElement(props) {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  return (
    <>
      <Typography variant='overline' className={commonClasses.mb1}>
        PAYMENT METHOD
      </Typography>
      <StripeCardElement
        className={classes.cardElement}
        options={CARD_ELEMENT_OPTIONS}
        onBlur={props.onBlur}
        onChange={props.onChange}
      />
      {props.error ? (
        <Typography variant='body1' className={commonClasses.error}>
          {props.error}
        </Typography>
      ) : null}
      <Typography
        className={[commonClasses.mt1, commonClasses.textRight].join(' ')}
      >
        <FontAwesomeIcon
          icon={['fab', 'cc-visa']}
          size='2x'
          className='fa-fw'
        />
        <FontAwesomeIcon
          icon={['fab', 'cc-amex']}
          size='2x'
          className='fa-fw'
        />
        <FontAwesomeIcon
          icon={['fab', 'cc-discover']}
          size='2x'
          className='fa-fw'
        />
        <FontAwesomeIcon
          icon={['fab', 'cc-mastercard']}
          size='2x'
          className='fa-fw'
        />
        <FontAwesomeIcon
          icon={['fab', 'cc-diners-club']}
          size='2x'
          className='fa-fw'
        />
        <FontAwesomeIcon icon={['fab', 'cc-jcb']} size='2x' className='fa-fw' />
      </Typography>
      <Typography
        variant='caption'
        component='p'
        className={commonClasses.textRight}
      >
        Payments handled by{' '}
        <a href='https://stripe.com' target='_blank' tabIndex='-1' rel="noopener noreferrer">
          Stripe
        </a>
      </Typography>
    </>
  )
}

CardElement.propTypes = {
  error: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

CardElement.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
}

export default CardElement
