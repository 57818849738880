import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Form as FormikForm, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 600,
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}))

function ProfileForm(props) {
  const { email, initialValues, onSubmit } = props
  const classes = useStyles()

  const handleSubmit = async (values, actions) => {
    try {
      await onSubmit(values)
    } catch (err) {
      console.error('Error updating profile')
      console.error(err)
    }
    actions.setSubmitting(false)
  }

  return (
    <Card className={classes.card}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, dirty, isSubmitting, handleReset, handleChange }) => (
          <FormikForm>
            <CardContent>
              <TextField
                className={classes.input}
                disabled
                fullWidth
                variant='filled'
                helperText='We do not currently support changing email addresses'
                id='email'
                name='email'
                label='Email'
                value={email}
                InputLabelProps={{ shrink: !!email }}
              />
              <TextField
                className={classes.input}
                fullWidth
                id='given-name'
                name='given_name'
                label='First Name'
                value={values.given_name}
                onChange={handleChange}
                InputLabelProps={{ shrink: !!values.given_name }}
              />
              <TextField
                className={classes.input}
                fullWidth
                id='family-name'
                name='family_name'
                label='Last Name'
                value={values.family_name}
                onChange={handleChange}
                InputLabelProps={{ shrink: !!values.family_name }}
              />
            </CardContent>
            <CardActions>
              <Button
                type='submit'
                disabled={!dirty || isSubmitting}
                variant={dirty ? 'contained' : null}
                color={dirty ? 'primary' : 'secondary'}
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
              <Button disabled={!dirty || isSubmitting} onClick={handleReset}>
                Cancel
              </Button>
            </CardActions>
          </FormikForm>
        )}
      </Formik>
    </Card>
  )
}

ProfileForm.propTypes = {
  initialValues: PropTypes.object,
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

ProfileForm.defaultProps = {
  initialValues: {},
}

export default ProfileForm
