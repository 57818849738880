import { Card, CardContent, Grid, Link, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { dismissPolicyFlag } from 'actions/policies'
import Navigation from 'components/Navigation'
import ScreenTitle from 'components/ScreenTitle'
import AlertsTable from 'components/Tables/Alerts'
import ExpiringCoveragesTable from 'components/Tables/ExpiringCoverages'
import SubcontactorMissingInfoTable from 'components/Tables/SubcontractorsMissingInfo'
import { DATE_FORMAT } from 'config/constants'
import routes from 'config/routes'
import { addDays, format } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { renderLink } from 'utils'

const useStyles = makeStyles((theme) => ({
  notifications: {
    textAlign: 'center',
    margin: theme.spacing(4, 0),
  },
  subcontractors: {
    textAlign: 'center',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  alertIcon: {
    color: theme.palette.error.main,
  },
  fill: {
    height: '100%',
  },
  alertsMessage: {
    marginTop: theme.spacing(2),
  },
}))

const policyFlagDescriptions = {
  policy_terms: 'The policy terms may have exclusions.',
  residential_insurer_exclusion:
    'The policy may not cover the type of work being performed by this subcontractor.',
  under_insured:
    'The insured amount of one or more policies is below the required minimum coverage.',
}

const policyFlagDefaultDescription =
  'Please contact your insurance professional for additional details.'

function Dashboard(props) {
  const classes = useStyles()

  // Determine if subcontractors are missing required details
  const subsMissingInfo = React.useMemo(
    () =>
      props.subcontractors.filter(
        (s) => s.active && !(s.has_w9 && s.has_agreement && s.has_policy)
      ),
    [props.subcontractors]
  )

  const activeSubs = React.useMemo(
    () => props.subcontractors.filter((s) => s.active).map((s) => s.id),
    [props.subcontractors]
  )

  const coveragesExpiringSoon = React.useMemo(() => {
    // get all coverages as list, include subcontractor id
    const coverages = props.policies
      .filter((policy) => activeSubs.includes(policy.subcontractor))
      .map((policy) =>
        policy.coverages.map((coverage) => ({
          ...coverage,
          subcontractor: policy.subcontractor,
        }))
      )
      .reduce((all, coverages) => all.concat(coverages), [])
    const processed = []
    const expirationCutoffs = [0, 30, 120]
    return expirationCutoffs
      .map((cutoff) =>
        coverages.filter((coverage) => {
          // skip processed coverages
          if (processed.includes(coverage.id)) {
            return false
          }
          // is the coverage expiring within the current cutoff?
          const expiryDate = format(addDays(new Date(), cutoff), DATE_FORMAT)
          const expiring = coverage.expiration_date <= expiryDate
          if (!expiring) {
            // no need to check further if this coverage isn't expiring
            return false
          }
          // is there a newer coverage that replaces this one already?
          const newerCoverages = coverages.filter(
            (coverage2) =>
              coverage2.subcontractor === coverage.subcontractor &&
              coverage2.kind === coverage.kind &&
              coverage2.expiration_date > expiryDate
          )
          // Mark as processed now that we have checked for new coverage
          processed.push(coverage.id)
          // Add notification if no newer coverages found
          return newerCoverages.length === 0
        })
      )
      .reduce((all, group) => all.concat(group), [])
  }, [props.policies, activeSubs])

  const alerts = React.useMemo(
    () =>
      props.policies
        .filter((policy) => activeSubs.includes(policy.subcontractor))
        .map((policy) =>
          policy.flags
            .filter((flag) => !flag.dismissed)
            .map((flag) => ({
              ...flag,
              description:
                policyFlagDescriptions[flag.code] ||
                policyFlagDefaultDescription,
              subcontractor: props.subcontractors.find(
                (s) => s.id === policy.subcontractor
              ),
            }))
        )
        .reduce((all, flags) => all.concat(flags), []),
    [props.policies, props.subcontractors, activeSubs]
  )

  const dismissAlert = async (subId, id) => {
    try {
      await props.dismissPolicyFlag(props.currentOrganization.id, subId, id)
    } catch (err) {
      console.error('unable to dismiss policy flag')
      console.error(err)
    }
  }

  return (
    <Navigation>
      <Container>
        <ScreenTitle>Dashboard</ScreenTitle>
        <Grid container spacing={3}>
          {props.subcontractors.length === 0 && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant='h5' gutterBottom>
                    Welcome to Cert Comply
                  </Typography>
                  <Typography variant='body1'>
                    Cert Comply helps track all of your compliance information
                    for you and your entire business. We give you insights into
                    what you might be missing and help track tasks before they
                    become a problem. Cert Comply helps you manage:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant='body1'>
                        Documents like W9s and operating agreements
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body1'>
                        Insurance policies
                      </Typography>
                    </li>
                  </ul>
                  <Typography variant='body1'>
                    <Link
                      color='secondary'
                      component={renderLink(routes.subcontractorNew)}
                    >
                      Get started by adding subcontractors
                    </Link>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs={12}>
            <Card className={classes.fill}>
              <CardContent className={classes.content}>
                <Typography variant='h6' gutterBottom>
                  Alerts
                </Typography>
                {alerts.length > 0 ? (
                  <>
                    <AlertsTable alerts={alerts} onDismiss={dismissAlert} />
                    <Typography
                      variant='caption'
                      className={classes.alertsMessage}
                    >
                      <strong>IMPORTANT:</strong>
                      The insurance provided by subcontractors listed above may
                      not meet recommended limits and coverages. Please contact
                      your insurance professional for additional details. To
                      better protect you, Cert Comply recommends you not allow
                      any subcontractor listed here to start their work until
                      each outstanding matter has been addressed.
                    </Typography>
                  </>
                ) : (
                  <Typography variant='body1' className={classes.notifications}>
                    No Alerts
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.fill}>
              <CardContent className={classes.content}>
                <Typography variant='h6' gutterBottom>
                  Subcontractors
                </Typography>
                {subsMissingInfo.length > 0 ? (
                  <SubcontactorMissingInfoTable
                    subcontractors={subsMissingInfo}
                  />
                ) : (
                  <Grid
                    container
                    justify='center'
                    alignItems='center'
                    className={classes.fill}
                  >
                    <Typography
                      variant='body1'
                      className={classes.notifications}
                    >
                      {props.subcontractors.length > 0
                        ? 'Everyone is compliant!'
                        : 'Add subcontractors to get started!'}
                    </Typography>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.fill}>
              <CardContent className={classes.content}>
                <Typography variant='h6' gutterBottom>
                  Policies
                </Typography>
                {coveragesExpiringSoon.length > 0 ? (
                  <ExpiringCoveragesTable
                    coverages={coveragesExpiringSoon}
                    subcontractors={props.subcontractors}
                  />
                ) : (
                  <Grid
                    container
                    justify='center'
                    alignItems='center'
                    className={classes.fill}
                  >
                    <Typography
                      variant='body1'
                      className={classes.notifications}
                    >
                      {props.policies.length > 0
                        ? 'Everyone is compliant!'
                        : 'Add policies to get started!'}
                    </Typography>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Navigation>
  )
}

Dashboard.propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  dismissPolicyFlag: PropTypes.func.isRequired,
  policies: PropTypes.array.isRequired,
  subcontractors: PropTypes.array.isRequired,
}

const mapState = (state) => ({
  currentOrganization: state.app.currentOrganization,
  policies: state.policies,
  subcontractors: state.subcontractors,
})

const mapDispatch = {
  dismissPolicyFlag,
}

export default connect(mapState, mapDispatch)(Dashboard)
