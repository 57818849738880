import { Container } from '@material-ui/core'
import { createOrganization } from 'actions/organizations'
import { performStartup } from 'actions'
import CreateOrganizationForm from 'components/Forms/CreateOrganization'
import Navigation from 'components/Navigation'
import ScreenTitle from 'components/ScreenTitle'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

function Create(props) {
  const [complete, setComplete] = useState()

  const submit = async values => {
    try {
      setComplete(true)
      await props.createOrganization(values)
      await props.performStartup()
    } catch (err) {
      // re-raise for form to handle
      throw err
    }
  }

  if (complete) {
    return <Redirect to={routes.dashboard} />
  }

  return (
    <Navigation>
      <Container>
        <ScreenTitle>Create Organization</ScreenTitle>
        <CreateOrganizationForm onSubmit={submit} />
      </Container>
    </Navigation>
  )
}

Create.propTypes = {
  createOrganization: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  performStartup: PropTypes.func.isRequired,
}

const mapState = state => ({
  organizations: state.organizations,
})

const mapDispatch = {
  createOrganization,
  performStartup,
}

export default connect(
  mapState,
  mapDispatch
)(Create)
