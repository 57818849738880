import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Paper,
  Radio,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import {
  CardElement as StripeCardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { savePayment, subscribe, validateCoupon } from 'actions/subscription'
import CardElement from 'components/CardElement'
import ServiceAgreement from 'components/ServiceAgreement'
import routes from 'config/routes'
import { Form as FormikForm, Formik } from 'formik'
import { useCommonStyles } from 'hooks'
import React from 'react'
import { FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  acceptTerms: {
    color: 'white',
  },
  planTitle: {
    display: 'inline-block',
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(0, 2),
    borderRadius: theme.spacing(0.5),
  },
  couponDetails: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    display: 'inline-block',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
}))

function New(props) {
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const [paymentMethod, setPaymentMethod] = React.useState()
  const [plan, setPlan] = React.useState()
  const [coupon, setCoupon] = React.useState()
  const [paymentError, setPaymentError] = React.useState()
  const [editCoupon, setEditCoupon] = React.useState(false)
  const [validatingCoupon, setValidatingCoupon] = React.useState(false)
  const [showTerms, setShowTerms] = React.useState(false)
  const [subscribing, setSubscribing] = React.useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const initialValues = {
    plan: process.env.REACT_APP_STRIPE_PLAN_ID,
    payment: false,
    confirm: false,
    coupon: '',
    validatedCoupon: null,
  }

  const validate = (values) => {
    const errors = {}
    const required = ['plan', 'payment', 'confirm']
    // eslint-disable-next-line
    for (let key of required) {
      if (!values[key]) {
        errors[key] = 'This field is required'
      }
    }
    return errors
  }

  const handleSubmit = async (values, actions) => {
    const { plan, validatedCoupon } = values

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return
    }

    if (!!values.coupon && !values.validatedCoupon) {
      actions.setFieldError('coupon', 'Please enter a valid coupon or cancel')
      actions.setSubmitting(false)
      return
    }

    setPaymentError(null)
    try {
      const cardElement = elements.getElement(StripeCardElement)
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })

      if (error) {
        setPaymentError(error.message)
        actions.setSubmitting(false)
        console.error('[error]', error)
        return
      }

      setPaymentMethod(paymentMethod.id)
      setPlan(plan)
      setCoupon(validatedCoupon && validatedCoupon.id)
      setShowTerms(true)
    } catch (err) {
      console.error('error retrieving card element')
      console.error(err)
    } finally {
      actions.setSubmitting(false)
    }
  }

  const acceptTerms = async () => {
    try {
      setShowTerms(false)
      setSubscribing(true)
      await props.savePayment(paymentMethod)
      await props.subscribe(plan, coupon)
    } catch (err) {
      setPaymentError(
        'We were unable to save your payment method. Check your card info and try again or try a different card.'
      )
      console.error('error subscribing')
      console.error(err)
    } finally {
      setSubscribing(false)
    }
  }

  const applyCoupon = async (coupon, actions) => {
    try {
      setValidatingCoupon(true)
      const validatedCoupon = await props.validateCoupon(coupon)
      actions.setFieldValue('validatedCoupon', validatedCoupon)
    } catch (err) {
      if (err.response && err.response.data) {
        actions.setFieldError('coupon', err.response.data.code)
      } else {
        console.error('error validating coupon code')
        console.error(err)
        actions.setFieldError('coupon', 'Please try again')
      }
    } finally {
      setValidatingCoupon(false)
    }
  }

  if (!!props.subscription) {
    return <Redirect to={routes.subscription} />
  }

  return (
    <Card className={commonClasses.mw600}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({
          dirty,
          errors,
          handleChange,
          handleBlur,
          handleReset,
          isSubmitting,
          isValid,
          touched,
          values,
          setFieldError,
          setFieldValue,
          setFieldTouched,
        }) => (
          <FormikForm>
            <CardContent>
              <Typography className={commonClasses.mb2}>
                A subscription is required to use Cert Comply. Please select a
                plan to get started.
              </Typography>
              <hr className={commonClasses.hr} />
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2} className={commonClasses.textCenter}>
                  <Radio
                    name='plan'
                    checked={
                      values.plan === process.env.REACT_APP_STRIPE_PLAN_ID
                    }
                    value={process.env.REACT_APP_STRIPE_PLAN_ID}
                  />
                </Grid>
                <Grid item xs={10}>
                  <div className='plan'>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item>
                        <Typography
                          variant='h6'
                          className={[
                            classes.planTitle,
                            commonClasses.fontHeavy,
                          ].join(' ')}
                        >
                          <label htmlFor='plan'>BASIC</label>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant='h4'
                          className={commonClasses.fontHeavy}
                        >
                          <Typography
                            variant='caption'
                            className={commonClasses.vAlignSuper}
                          >
                            $
                          </Typography>
                          <FormattedNumber value={1750} />
                          <Typography variant='caption'>/year</Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                    <ul className='benefits'>
                      <li>
                        <Typography className={commonClasses.fontHeavy}>
                          Track up to 25 Subcontractors
                        </Typography>
                      </li>
                      <li>
                        <Typography className={commonClasses.fontHeavy}>
                          Unlimited Policies
                        </Typography>
                      </li>
                    </ul>
                  </div>
                </Grid>
              </Grid>
              <hr className={commonClasses.hr} />
              <CardElement
                error={paymentError || (touched.payment && errors.payment)}
                onBlur={() => setFieldTouched('payment', true)}
                onChange={(event) => setFieldValue('payment', event.complete)}
              />
              <div>
                {editCoupon ? (
                  <>
                    <Grid
                      container
                      spacing={2}
                      className={commonClasses.mt2}
                      alignItems='center'
                    >
                      <Grid item>
                        <TextField
                          size='small'
                          fullWidth
                          id='coupon'
                          name='coupon'
                          label='Coupon Code'
                          variant='outlined'
                          value={values.coupon}
                          disabled={!!values.validatedCoupon}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.coupon && !!errors.coupon}
                          helperText={
                            touched.coupon && !!errors.coupon
                              ? errors.coupon
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item>
                        {!!values.validatedCoupon ? (
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                              setFieldValue('validatedCoupon', null)
                              setFieldValue('coupon', '')
                            }}
                          >
                            Remove
                          </Button>
                        ) : (
                          <>
                            <Button
                              variant='contained'
                              color='primary'
                              className={commonClasses.mr1}
                              disabled={validatingCoupon}
                              onClick={() =>
                                applyCoupon(values.coupon, {
                                  setFieldValue,
                                  setFieldError,
                                })
                              }
                            >
                              {validatingCoupon ? 'Checking' : 'Apply'}
                            </Button>
                            <Button
                              color='primary'
                              disabled={validatingCoupon}
                              onClick={() => {
                                setFieldValue('coupon', '')
                                setEditCoupon(false)
                              }}
                            >
                              Cancel
                            </Button>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    {!!values.validatedCoupon ? (
                      <Paper className={classes.couponDetails}>
                        <Typography variant='h6'>
                          {values.validatedCoupon.name}
                        </Typography>
                        <Typography>
                          {!!values.validatedCoupon.percent_off
                            ? `${values.validatedCoupon.percent_off}% off`
                            : `$${
                                values.validatedCoupon.amount_off / 100.0
                              } off`}{' '}
                          your subscription{' '}
                          {values.validatedCoupon.duration === 'repeating'
                            ? `for ${values.validatedCoupon.duration_in_months} years`
                            : values.validatedCoupon.duration === 'once'
                            ? 'for one year'
                            : 'for forever!'}
                        </Typography>
                      </Paper>
                    ) : null}
                  </>
                ) : (
                  <Button color='secondary' onClick={() => setEditCoupon(true)}>
                    Have a coupon code?
                  </Button>
                )}
              </div>
              <hr className={commonClasses.hr} />
              <Typography variant='overline'>CONFIRM PAYMENT</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='confirm'
                      checked={values.confirm}
                      onChange={() => setFieldValue('confirm', !values.confirm)}
                      onBlur={() => setFieldTouched('confirm', true)}
                    />
                  }
                  label={
                    'You understand that by subscribing, you agree to have the payment method on file charged automatically each billing period.'
                  }
                />
              </FormGroup>
              {errors.touched && !!errors.confirm ? (
                <Typography className={commonClasses.error}>
                  This field is required
                </Typography>
              ) : null}
            </CardContent>
            <CardActions>
              <Button
                type='submit'
                disabled={
                  !stripe ||
                  !elements ||
                  !isValid ||
                  isSubmitting ||
                  subscribing
                }
                variant='contained'
                color='primary'
              >
                {isSubmitting || subscribing ? 'Subscribing...' : 'Subscribe'}
              </Button>
            </CardActions>
          </FormikForm>
        )}
      </Formik>
      <Dialog
        fullScreen={fullScreen}
        open={showTerms}
        onClose={() => setShowTerms(false)}
        aria-labelledby='accept-terms-dialog-title'
      >
        <DialogTitle id='accept-terms-dialog-title'>
          Service and User Agreement
        </DialogTitle>
        <DialogContent>
          <ServiceAgreement />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTerms(false)} color='primary'>
            Disagree
          </Button>
          <Button onClick={() => acceptTerms()} color='secondary' variant='contained' className={classes.acceptTerms}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

const mapState = (state) => ({
  subscription: state.subscription,
})

const mapDispatch = {
  savePayment,
  subscribe,
  validateCoupon,
}

export default connect(mapState, mapDispatch)(New)
