import { Button, Grid } from '@material-ui/core'
import CardElement from 'components/CardElement'
import { useCommonStyles } from 'hooks'
import PropTypes from 'prop-types'
import React from 'react'

function Edit(props) {
  const commonClasses = useCommonStyles()

  return (
    <>
      <Grid item xs={12} md={8}>
        <CardElement error={props.error} />
      </Grid>
      <Grid item xs={12} md={4} className={commonClasses.textCenter}>
        <Button
          color='primary'
          variant='contained'
          onClick={props.onUpdate}
          disabled={props.updating}
          className={commonClasses.mr1}
        >
          {props.updating ? 'Saving' : 'Save'}
        </Button>
        <Button
          color='primary'
          disabled={props.updating}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
      </Grid>
    </>
  )
}

Edit.propTypes = {
  error: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
}

export default Edit
