import api, { urls } from 'config/api'
/**
 * Action types
 */
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'

/**
 * Action creators
 */
const _setSubscription = (subscription) => ({
  type: SET_SUBSCRIPTION,
  subscription,
})

/**
 * Actions
 */

export const getSubscription = (orgId) => async (dispatch, getState) => {
  const resp = await api.get(urls.subscription({ orgId }))
  dispatch(_setSubscription(resp.data || null))
}

export const savePayment = (paymentMethod) => async (dispatch, getState) => {
  const orgId = getState().app.currentOrganization.id
  const resp = await api.post(urls.paymentMethod({ orgId }), {
    payment_method: paymentMethod,
  })
  await dispatch(getSubscription(orgId))
  return resp.data
}

export const subscribe = (plan, coupon) => async (dispatch, getState) => {
  const orgId = getState().app.currentOrganization.id
  const resp = await api.post(urls.subscription({ orgId }), {
    plan,
    coupon,
  })
  dispatch(_setSubscription(resp.data))
  return resp.data
}

// Currently the only supported update is whether or not the
// subscription should cancel at the end of the current period.
export const updateSubscription = (cancel) => async (dispatch, getState) => {
  const orgId = getState().app.currentOrganization.id
  const resp = await api.put(urls.subscription({ orgId }), {
    cancel_at_period_end: cancel,
  })
  dispatch(_setSubscription(resp.data))
  return resp.data
}

export const validateCoupon = (code) => async () => {
  const resp = await api.post(urls.coupon(), {
    code,
  })
  return resp.data
}
