import green from '@material-ui/core/colors/green'
import { createMuiTheme } from '@material-ui/core/styles'

const CertComplyBlack = '#151515'
// const CertComplyGray = '#6b6b6b'
const CertComplyLightGray = '#dddddd'
const CertComplyBlue = '#00acee'

export const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main: CertComplyBlack,
    },
    secondary: {
      main: CertComplyBlue,
    },
    type: 'dark',
  },
})

export default createMuiTheme({
  palette: {
    primary: {
      main: CertComplyBlack,
    },
    secondary: {
      main: CertComplyBlue,
    },
    success: {
      main: green[500],
    },
    background: {
      default: CertComplyLightGray,
    },
    // type: 'dark',
  },
})
