import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Fab, makeStyles, Typography } from '@material-ui/core'
import LoadingWave from 'components/LoadingWave'
import DefaultTable from 'components/Tables/Default'
import { PermissionsContext } from 'hooks'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  textCenter: {
    textAlign: 'center',
  },
  bodyNoOrgs: {
    padding: theme.spacing(3, 0),
  },
  white: {
    color: 'white',
  },
  btnMarginRight: {
    marginRight: theme.spacing(1),
  },
  addBtn: {
    float: 'right',
  },
}))

const ActionsCell = (onDownload, onDelete, subId, orgId, classes, permissions) => ({
  cell: { value },
  row,
}) => {
  const download = (event) => {
    event.preventDefault()
    onDownload(orgId, row.original.id)
  }

  const delete_ = async () => {
    const msg =
      'Are you sure you want to delete this document? You cannot undo this action.'
    if (window.confirm(msg)) {
      try {
        await onDelete(orgId, subId, row.original.id)
      } catch (err) {
        console.error('Unable to delete document')
        console.error(err)
      }
    }
  }

  return (
    <div>
      <Fab
        size='small'
        color='primary'
        aria-label='download'
        onClick={download}
        className={classes.btnMarginRight}
      >
        <FontAwesomeIcon icon='cloud-download' className='fa-fw' />
      </Fab>
      {permissions.canDeleteDocuments ? (
        <Fab
          size='small'
          color='secondary'
          aria-label='delete'
          onClick={delete_}
        >
          <FontAwesomeIcon
            icon='trash-alt'
            className={`fa-fw ${classes.white}`}
          />
        </Fab>
      ) : null}
    </div>
  )
}

function DocumentsTable({
  loading,
  onDelete,
  onDownload,
  onAdd,
  documents,
  subcontractor,
}) {
  const classes = useStyles()
  const data = React.useMemo(() => documents, [documents])
  const permissions = React.useContext(PermissionsContext)
  const columns = React.useMemo(
    () => [
      {
        Header: 'FILENAME',
        accessor: 'filename',
      },
      {
        Header: 'UPLOADED',
        accessor: (row) => {
          // moment handles browser timezone automagically
          // add the "Z" to indicate the time is UTC
          const uploaded_at = moment(row.uploaded_at + 'Z')
          return uploaded_at.format('MM/DD/yyyy')
        },
      },
      {
        Header: 'ACTIONS',
        Cell: ActionsCell(
          onDownload,
          onDelete,
          subcontractor.id,
          subcontractor.organization,
          classes,
          permissions
        ),
      },
    ],
    [onDelete, onDownload, classes, subcontractor, permissions]
  )

  if (loading) {
    return (
      <div className={classes.bodyNoOrgs}>
        <LoadingWave />
      </div>
    )
  }

  return (
    <div>
      {documents.length > 0 ? (
        <Button
          color='primary'
          variant='contained'
          startIcon={<FontAwesomeIcon icon='plus' />}
          className={classes.addBtn}
          onClick={onAdd}
        >
          Add File
        </Button>
      ) : null}
      {documents.length === 0 ? (
        <div className={classes.textCenter}>
          <Typography vairant='body1' className={classes.bodyNoOrgs}>
            There aren't any documents
          </Typography>
          <Button
            variant='contained'
            color='primary'
            onClick={onAdd}
            startIcon={<FontAwesomeIcon icon='plus' fixedWidth />}
          >
            Add File
          </Button>
        </div>
      ) : (
        <DefaultTable columns={columns} data={data} />
      )}
    </div>
  )
}

DocumentsTable.propTypes = {
  loading: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  subcontractor: PropTypes.object.isRequired,
}

export default DocumentsTable
