import { Card, CardContent, Container, makeStyles } from '@material-ui/core'
import { createDocument, getDocument } from 'actions/documents'
import Navigation from 'components/Navigation'
import PolicyUpload from 'components/PolicyUpload'
import ScreenTitle from 'components/ScreenTitle'
import routes, { formatRoute } from 'config/routes'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getSubcontractor } from 'actions/subcontractors'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
  },
  center: {
    textAlign: 'center',
  },
  loadingWave: {
    margin: theme.spacing(4, 0),
  },
}))

function Upload({
  createDocument,
  currentOrganization,
  subcontractor,
  getDocument,
  getSubcontractor,
  history,
}) {
  const classes = useStyles()

  const loadDocument = async (id) => {
    return await getDocument(currentOrganization.id, id)
  }

  const handleUpload = async (file) => {
    return await createDocument(currentOrganization.id, subcontractor.id, {
      kind: 'policy',
      filename: file.filename,
      upload_id: file.serverId,
    })
  }

  const handleFinish = async (policy) => {
    // refresh subcontractor to get in case address info was updated
    getSubcontractor(currentOrganization.id, subcontractor.id)
    history.push(formatRoute(routes.policyNew, { subId: subcontractor.id }), {
      policy,
    })
  }

  return (
    <Navigation>
      <Container>
        <ScreenTitle>Upload Certificate of Insurance</ScreenTitle>
        <Card className={classes.card}>
          <CardContent>
            <PolicyUpload
              getDocument={loadDocument}
              onFinish={handleFinish}
              onUpload={handleUpload}
            />
          </CardContent>
        </Card>
      </Container>
    </Navigation>
  )
}

Upload.propTypes = {
  createDocument: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getDocument: PropTypes.func.isRequired,
  getSubcontractor: PropTypes.func.isRequired,
  subcontractor: PropTypes.object.isRequired,
}

const mapState = (state) => ({
  currentOrganization: state.app.currentOrganization,
})

const mapDispatch = {
  createDocument,
  getDocument,
  getSubcontractor,
}

export default connect(mapState, mapDispatch)(Upload)
