import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Menu as MuiMenu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import logo from 'assets/images/logo.png'
import routes from 'config/routes'
import { PermissionsContext } from 'hooks'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  logoContainer: {
    margin: theme.spacing(3),
    textAlign: 'center',
  },
  listItemIcon: {
    marginRight: theme.spacing(1),
  },
  organizationTitle: {
    fontWeight: 'bold !important',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  organizationMenu: {
    maxWidth: 250,
  },
  email: {
    marginTop: theme.spacing(1),
    fontSize: '0.8rem',
  },
}))

// Use react-router-dom `Link`
// https://material-ui.com/guides/composition/#link
const renderLink = (to) =>
  React.forwardRef((props, ref) => <Link to={to} {...props} innerRef={ref} />)

function Menu(props) {
  const { email, logout, organizations, currentOrganization } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState()

  const handleOpen = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const selectOrganization = (id) => {
    props.selectOrganization(id)
    handleClose()
  }

  return (
    <PermissionsContext.Consumer>
      {(permissions) => (
        <div className={classes.toolbar}>
          <div className={classes.logoContainer}>
            <img src={logo} alt='Cert Comply' />
          </div>
          {!!currentOrganization > 0 ? (
            <>
              <List>
                <ListItem button onClick={handleOpen}>
                  <ListItemText>
                    <Typography className={classes.organizationTitle} noWrap>
                      {currentOrganization.name}
                      <FontAwesomeIcon icon='angle-down' className='fa-fw' />
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
              <MuiMenu
                id='organization-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.organizationMenu}
              >
                <ListSubheader>Organizations</ListSubheader>
                {organizations.map((o) => (
                  <MenuItem key={o.id} onClick={() => selectOrganization(o.id)}>
                    <Typography noWrap>{o.name}</Typography>
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={handleClose}
                  component={renderLink(routes.organizationsNew)}
                >
                  <Typography noWrap>
                    <FontAwesomeIcon
                      icon='plus'
                      className={`fa-fw ${classes.listItemIcon}`}
                    />
                    Create Organization
                  </Typography>
                </MenuItem>
              </MuiMenu>
              <Divider />
            </>
          ) : null}
          <List>
            <ListItem button component={renderLink(routes.dashboard)}>
              <ListItemText>
                <FontAwesomeIcon
                  icon='home'
                  className={`fa-fw ${classes.listItemIcon}`}
                />
                Dashboard
              </ListItemText>
            </ListItem>
            <ListItem button component={renderLink(routes.subcontractors)}>
              <ListItemText>
                <FontAwesomeIcon
                  icon='address-card'
                  className={`fa-fw ${classes.listItemIcon}`}
                />
                Subcontractors
              </ListItemText>
            </ListItem>
            {permissions.canManageTeam ? (
              <ListItem button component={renderLink(routes.team)}>
                <ListItemText>
                  <FontAwesomeIcon
                    icon='users'
                    className={`fa-fw ${classes.listItemIcon}`}
                  />
                  Team
                </ListItemText>
              </ListItem>
            ) : null}
            {permissions.canManageBilling ? (
              <ListItem button component={renderLink(routes.subscription)}>
                <ListItemText>
                  <FontAwesomeIcon
                    icon='credit-card'
                    className={`fa-fw ${classes.listItemIcon}`}
                  />
                  Subscription
                </ListItemText>
              </ListItem>
            ) : null}
          </List>
          <Divider />
          <List>
            <ListSubheader>
              <Typography noWrap className={classes.email}>
                {email}
              </Typography>
            </ListSubheader>
            <ListItem button component={renderLink(routes.profile)}>
              <ListItemText>
                <FontAwesomeIcon
                  icon='user'
                  className={`fa-fw ${classes.listItemIcon}`}
                />
                Profile
              </ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={logout}
              component={renderLink(routes.dashboard)}
            >
              <ListItemText>
                <FontAwesomeIcon
                  icon='sign-out'
                  className={`fa-fw ${classes.listItemIcon}`}
                />
                Log Out
              </ListItemText>
            </ListItem>
          </List>
        </div>
      )}
    </PermissionsContext.Consumer>
  )
}

Menu.propTypes = {
  email: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object,
  organizations: PropTypes.array,
  selectOrganization: PropTypes.func.isRequired,
}

Menu.defaultProps = {
  organizations: [],
}

export default Menu
