import { Button, Grid, Typography } from '@material-ui/core'
import { useCommonStyles } from 'hooks'
import PropTypes from 'prop-types'
import React from 'react'

function Details(props) {
  const commonClasses = useCommonStyles()

  return (
    <>
      <Grid item xs={12} md={6} className={commonClasses.textCenter}>
        <Typography variant='overline'>payment method</Typography>
        <Typography variant='h6' className={commonClasses.fontHeavy}>
          {props.card.brand.toUpperCase()} ending in {props.card.last4}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} className={commonClasses.textCenter}>
        {props.isActive ? (
          <Button color='primary' variant='contained' onClick={props.onEdit}>
            Update
          </Button>
        ) : null}
      </Grid>
    </>
  )
}

Details.propTypes = {
  isActive: PropTypes.bool.isRequired,
  card: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
}

export default Details
