import React from 'react'
import './index.scss'

export default function ServiceAgreement() {
  return (
    <>
      <h4>Cert Comply Software Services Agreement</h4>
      <p>
        By entering into this Agreement, Cert Comply and Customer agree that the
        following terms and conditions will apply to the services provided under
        this Agreement.
      </p>
      <ol>
        <li>
          Services
          <ol>
            <li>
              Cert Comply will use commercially reasonable efforts to provide
              Customer with the following services (hereinafter referred to as
              the “Services”):
              <ul>
                <li>
                  Use of the web application that collects, stores, and reviews
                  insurance certificates for contractors.
                </li>
              </ul>
            </li>
            <li>
              Customer shall be granted the corresponding number of user
              accounts in accordance with the subscription.
            </li>
            <li>
              Customer acknowledges that it is being granted a limited license
              to use Cert Comply Software (as defined below).
            </li>
          </ol>
        </li>
        <li>
          Restrictions
          <ol>
            <li>
              Customer will not, and will not allow anyone else to:
              <ul>
                <li>
                  reverse engineer, decompile, disassemble or otherwise attempt
                  to discover the source code, object code or underlying
                  structure, know-how or algorithms relevant to the Services or
                  any software, documentation or data related to the Services
                  (“Software”);
                </li>
                <li>copy or republish the Services or Software;</li>
                <li>
                  modify, translate, or create derivative works based on the
                  Services or any Software;
                </li>
                <li>
                  use or access the Services to provide service bureau,
                  time-sharing or other computer hosting services to third
                  parties;
                </li>
                <li>
                  use or access the Services in order to build a similar or
                  competing product.
                </li>
              </ul>
            </li>
            <li>
              Customer agrees that Cert Comply owns all right, title, and
              interest in and to the Software, Services, and any deliverable
              provided under this Agreement. This ownership includes any
              modification, improvement, upgrade, derivative work, and feedback
              related to the Services and all intellectual property rights
              thereto. Customer agrees to assign all of its right, title, and
              interest it may have in any of the above to Cert Comply.
            </li>
          </ol>
        </li>
        <li>
          Customer Responsibilities
          <ol>
            <li>
              Customer will use the Services only in compliance with Cert
              Comply’s published policies then in effect, if any, and all
              applicable laws and regulations.
            </li>
            <li>
              Customer agrees to indemnify and hold harmless Cert Comply against
              any damage, loss, liability, settlement, and expense (including,
              without limitation, costs and attorneys’ fees) in connection with
              any claim or action that arises from an alleged violation of the
              foregoing or otherwise from Customer’s use of Services.
            </li>
            <li>
              Customer will immediately (i) notify Cert Comply of any
              unauthorized use of the Services or any known or suspected breach
              of security, (ii) use reasonable efforts to stop any known or
              suspected unauthorized use of the Services, (3) not provide false
              information to gain access to or use the Services.
            </li>
            <li>
              Customer is solely responsible for collecting, inputting, and
              updating information in conjunction with its use of the Services.
              It is Customer’s responsibility to ensure that the information
              does not, actually or potentially, infringe on any intellectual
              property right of a third party or contain any content that is
              obscene, defamatory, harassing, offensive or malicious.
            </li>
            <li>
              Customer grants to Cert Comply a limited, non-exclusive, and
              non-transferable license to copy, store, configure, display, and
              transmit information as necessary to provide the Services to
              Customer.
            </li>
            <li>
              Customer represents and warrants that it has permission to use any
              information it provides in its use of the Services.
            </li>
          </ol>
        </li>
        <li>
          Billing and Payment
          <ol>
            <li>
              Cert Comply will use a third party, Stripe, to carry out its
              billing.
            </li>
            <li>
              Customer will be billed pursuant to Stripe’s billing schedule,
              either on a monthly or annual basis as selected by Customer.
            </li>
            <li>
              In the event Customer does not make a required payment, Customer
              will lose access to the Services until payment is received.
            </li>
            <li>
              Customer will be responsible for paying any applicable taxes added
              onto the monthly fee. Such taxes may include, among others, sales
              tax, use tax, value added taxes, and other charges related to
              Customer’s purchase and use of the Services.
            </li>
          </ol>
        </li>
        <li>
          Term and Termination
          <ol>
            <li>
              The term of this Agreement begins on the Effective Date and will
              continue on a month-to-month basis until terminated by either
              party in accordance with this Section (“Term”).
            </li>
            <li>
              Either party may terminate the Agreement upon the material breach
              by the other party, so long as the breach has not been cured
              within 15 days of receipt of written notice of such material
              breach.
            </li>
            <li>
              Customer may terminate the Agreement at any time upon written
              request to Cert Comply sent to{' '}
              <a href='mailto:cancellations@cert-comply.com'>
                cancellations@cert-comply.com
              </a>
              .
            </li>
            <li>
              Cert Comply may suspend or terminate Customer’s access to the
              Services if Customer fails to pay any Fee. Suspension of access to
              the Services does not release Customer from its obligations under
              this Agreement, including its obligation to pay for the Services
              for the duration of the Term.
            </li>
            <li>
              Upon termination, Customer will:
              <ol className='list-letters'>
                <li>
                  immediately cease use of the Services but will be permitted
                  access to their documents for 10 days,
                </li>
                <li>
                  pay any outstanding balance on their account within 30 days.
                </li>
              </ol>
            </li>
            <li>
              In the event Cert Comply terminates this Agreement due to a
              material breach by Customer, Customer is responsible for
              immediately paying any unpaid amount due under this Agreement. In
              the event Customer terminates this Agreement due to a material
              breach by Cert Comply, Cert Comply is responsible for immediately
              refunding pro-rata any pre-paid amount for any unperformed
              Services.
            </li>
          </ol>
        </li>
        <li>
          Warranties
          <ol>
            <li>
              Cert Comply will use reasonable efforts in accordance with
              prevailing industry standards to provide the Services in a
              professional manner.
            </li>
            <li>
              Services may be temporarily unavailable for scheduled maintenance,
              for unscheduled emergency situations or for other situations
              outside Cert Comply’s reasonable control. Cert Comply will use
              reasonable efforts to provide advance notice of any scheduled
              interruption of the Services.
            </li>
            <li>
              Cert Comply warrants that the Services will perform in all
              material respects as described herein.
            </li>
            <li>
              CERT COMPLY DOES NOT GUARANTEE THAT THE SERVICES WILL PERFORM
              UNINTERRUPTED AND ERROR OR OMISSION FREE AND DOES NOT GUARANTEE
              THAT THE SERVICES WILL BE VIRUS FREE. CERT COMPLY DOES NOT
              GUARANTEE ANY PARTICULAR OUTCOME THROUGH CUSTOMER’S USE OF THE
              SERVICES.
            </li>
          </ol>
        </li>
        <li>
          Training.{' '}
          <span className='normal'>
            Cert Comply will provide access to instructional training videos of
            usage features.
          </span>
        </li>
        <li>
          Confidentiality.{' '}
          <span className='normal'>
            The parties understand and acknowledge that during the performance
            of this Agreement and the Services it may learn, receive or
            otherwise have access to the confidential or proprietary information
            or date of a party or its affiliates, customers, licensees or third-
            party contractors, whether oral or in writing, that are designated
            as confidential or would reasonably be understood to be confidential
            (“Confidential Information”). As such, each party agrees to exercise
            the same degree of care to keep such Confidential Information
            confidential as it uses to keep its own confidential information
            confidential, but in no event less than a reasonable standard of
            care. Each party, and its employees, users, independent contractors,
            and other agents shall not disclose, use, publish, or otherwise
            reveal, directly or indirectly or through any third party or to any
            of such party’s employees or agents that do not have a need to know
            such Confidential Information for the purpose of exercising that
            party’s rights or obligations under this Agreement.
            <br />
            <br />
            Confidential Information does not include information that (i) is
            generally known or available, (ii) becomes generally known or
            available through no fault of the receiving party, (iii) is
            rightfully known to the receiving party on a non-confidential basis
            at the time it receives such information, (iv) is given to the
            receiving party by a third party either has no restriction on
            disclosure or who the receiving party is unaware has a restriction
            on disclosure, (v) the receiving party independently develops the
            information without reference to the other party’s Confidential
            Information.
            <br />
            <br />
            Notwithstanding any of the foregoing, a party may disclose
            Confidential Information if required to do so by operation of law or
            by an instrumentality of the government, including any court,
            tribunal or administrative agency. The disclosing party must notify
            the other party prior to such disclosure if permitted by law. At the
            other party’s reasonable request and sole expense, the disclosing
            party shall assist the party in seeking to obtain a protective order
            or to otherwise minimize the extent of any such disclosure.
          </span>
        </li>
        <li>
          Limitation of Liability.{' '}
          <span className='normal'>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY
            OF A PERSON, CERT COMPLY AND ITS SUPPLIERS (INCLUDING BUT NOT
            LIMITED TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS,
            AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE
            RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS
            AGREEMENT OR TERMS AND CONDITIONS RELATED THERETO UNDER ANY
            CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR
            ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR CORRUPTION
            OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR
            TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY,
            INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER
            BEYOND CERT COMPLY’S REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS
            THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED
            THE FEES PAID BY CUSTOMER TO CERT COMPLY FOR THE SERVICES UNDER THIS
            AGREEMENT IN THE 12 MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE
            LIABILITY, IN EACH CASE, WHETHER OR NOT CERT COMPLYHAS BEEN ADVISED
            OF THE POSSIBILITY OF SUCH DAMAGES.
          </span>
        </li>
        <li>
          Indemnification.{' '}
          <span className='normal'>
            Cert Comply will hold Customer harmless from liability to third
            parties resulting from infringement by the Services of any United
            States patent or any copyright or misappropriation of any trade
            secret, provided Cert Comply is promptly notified of any threat,
            claim, and proceeding related thereto and is given reasonable
            assistance and the opportunity to assume sole control over defense
            and settlement. Cert Comply will not be responsible for any
            settlement it does not approve in writing. The foregoing obligations
            do not apply with respect to portions or components of the Service
            (i) not supplied by Cert Comply, (ii) made in whole or in part in
            accordance with Customer specifications, (iii) that are modified
            after delivery by Company, (iv) combined with other products,
            processes or materials where the alleged infringement related to
            such combination, (v) where Customer continues allegedly infringing
            activity after being notified thereof or after being informed of
            modifications that would have avoided the alleged infringement, or
            (vi) where Customer’s use of the Service is not strictly in
            accordance with this Agreement. If, due to a claim of infringement,
            the Services are held by a court of competent jurisdiction to be or
            are believed by Cert Comply to be infringing, Cert Comply may, at
            its option and expense (a) replace or modify the Service to be
            non-infringing provided that such modification or replacement
            contains substantially similar features and functionality, (b)
            obtain for Customer a license to continue using the Service or (c)
            if neither of the foregoing is commercially practicable, terminate
            this Agreement and Customer’s rights hereunder and provide Customer
            a refund of any prepaid, unused fees for the Services.
          </span>
        </li>
        <li>
          Service Level Agreement
          <ol>
            <li>
              <span className='underline'>Maintenance.</span> Cert Comply will
              perform maintenance as needed. We will give at least 48 hours’
              notice prior to any scheduled maintenance being performed.
              Maintenance will typically be performed after normal business
              hours (9pm – 5am EST). In the event emergency maintenance must be
              performed, we will provide as much notice as possible and will
              strive to minimize any impact on our Users during such
              maintenance.
            </li>
            <li>
              <span className='underline'>Uptime.</span> Cert Comply guarantees
              99.9% uptime. If you encounter any downtime, it is your
              responsibility to document it and notify us. We will provide
              service credits to User accounts for any documented downtime
              unless:
              <ol className='list-letters'>
                <li>
                  the downtime was caused by factors outside of Cert Comply’s
                  reasonable control, including, but not limited to, any force
                  majeure event, internet access or problems beyond the scope of
                  Cert Comply’s network (i.e. upstream servers from cloud
                  provider going down),
                </li>
                <li>
                  the downtime is a result of any action or inaction of any User
                  or any third party,
                </li>
                <li>
                  the downtime is a result of the equipment, software or other
                  technology of the User or any third party (other than
                  equipment under Cert Comply’s direct control),
                </li>
                <li>
                  the downtime is a result of other failures not attributable to
                  unavailability, or
                </li>
                <li>the downtime is a result of maintenance.</li>
              </ol>
            </li>
            <li>
              <span className='underline'>Service Credits.</span> Service
              Credits will be calculated as a percentage of the total charges
              due on the customer’s invoice for the monthly billing cycle in
              which the unavailability occurred, and will be applied
              proportionally to the services that were unavailable in accordance
              with the following schedule:
              <ol className='list-letters'>
                <li>
                  For monthly uptime percentages of less than 99.9% but equal to
                  or greater than 99.0% the customer will be eligible for a
                  Service Credit of 10% of the charges attributable to the
                  affected resources.
                </li>
                <li>
                  For monthly uptime percentages less than 99.0% the customer
                  will be eligible for a Service Credit of 25% of the charges
                  attributable to the affected resources.
                </li>
              </ol>
              Service Credits will be applied only against future payments of
              the Services otherwise due from the customer. In Cert Comply’s
              sole discretion, we may issue the Service Credit to the credit
              card used to pay for the billing cycle in which the unavailability
              occurred. Service Credits will not entitle the customer to any
              refund or other payment from Cert Comply. A Service Credit will be
              applicable and issued only if the credit amount for the applicable
              monthly billing cycle is greater than $1.00 USD. Service Credits
              may not be transferred or applied to any other account.
            </li>
            <li>
              <span className='underline'>Sole Remedy.</span> Unless otherwise
              provided in this Agreement, the customer’s sole and exclusive
              remedy for any unavailability, non-performance, or other failure
              by Cert Comply to provide the Services is the receipt of a Service
              Credit (if eligible) in accordance with the terms listed in this
              Section 11.
            </li>
            <li>
              <span className='underline'>Service Credit Requests.</span> To
              receive a Service Credit, Customer must submit a claim by emailing{' '}
              <a href='support@cert-comply.com'>support@cert-comply.com</a>. To
              be eligible, the credit request must be received by the end of the
              second billing cycle after the unavailability occurred and must
              include:
              <ol className='list-letters'>
                <li>the words “Service Credit Request” in the subject line,</li>
                <li>
                  the date and time of each unavailability incident the customer
                  is claiming, and
                </li>
                <li>
                  logs that document the errors and corroborate the claimed
                  unavailability.
                </li>
              </ol>
              If the monthly uptime percentage of such request is confirmed by
              Cert Comply and is less than the service commitment, then we will
              issue the Service Credit to the customer within one billing cycle
              following the month in which the request is confirmed by us.
              Customer failure to provide the request or other information as
              required above will disqualify Customer from receiving any
              corresponding Service Credit.
            </li>
            <li>
              <span className='underline'>Customer Support Response Time.</span>
              &nbsp;Cert Comply will respond to all support requests sent to{' '}
              <a href='support@cert-comply.com'>support@cert-comply.com</a>{' '}
              within 48 hours.{' '}
            </li>
          </ol>
        </li>
        <li>
          Miscellaneous
          <ol>
            <li>
              <span className='underline'>Entire Agreement.</span> This
              Agreement constitutes the sole and entire agreement of the parties
              with respect to the subject matter contained herein and therein,
              and supersedes all prior and contemporaneous understandings,
              agreements, representations, and warranties, both written and
              oral, regarding such subject matter.
            </li>
            <li>
              <span className='underline'>Severability.</span> If any provision
              of this Agreement is held invalid or unenforceable by a court of
              competent jurisdiction, such provision shall, at Cert Comply’s
              sole discretion, be removed or modified to the extent necessary to
              make such provision valid and enforceable and the remaining
              provisions of this Agreement shall remain in effect and
              enforceable in accordance with their terms.
            </li>
            <li>
              <span className='underline'>No Waiver.</span> Failure or delay of
              either party to exercise a right or power under this Agreement
              shall not operate as a waiver thereof, nor shall any single or
              partial exercise of a right or power preclude any other future
              exercise thereof.
            </li>
            <li>
              <span className='underline'>Notices.</span> All notices, requests,
              consents, claims, demands, waivers and other communications under
              this Agreement must be in writing and addressed to the other party
              at its address designated below, or as otherwise designated from
              time to time. Unless otherwise stated herein, all notices must be
              delivered by email, personal delivery, nationally recognized
              overnight courier or certified or registered mail. Notice by Cert
              Comply may be made by posting a notice in a conspicuous place on
              its website.
              <br />
              <br />
              Cert Comply:
              <br />
              <br />
              Cert Comply, Inc.
              <br />
              938 Main St.
              <br />
              Pleasant Grove, Utah 84062
              <br />
              <a href='mailto:support@cert-comply.com'>
                support@cert-comply.com
              </a>
              <br />
              <a href='mailto:scott@cert-comply.com'>scott@cert-comply.com</a>
              <br />
              <a href='mailto:dave@cert-comply.com'>dave@cert-comply.com</a>
              <br />
            </li>
            <li>
              <span className='underline'>Assignment.</span> Customer may not
              transfer or assign any right or obligation under this Agreement
              without Cert Comply’s prior written consent. Any attempted
              assignment without that consent will be void. Cert Comply reserves
              the right to transfer or assign this Agreement or any right or
              obligation under this Agreement at any time. Subject to the
              foregoing provisions of this Section 14.5, this Agreement shall be
              binding on and inure to the benefit of the parties’ successors and
              assigns.
            </li>
            <li>
              <span className='underline'>Attorneys' Fees and Costs.</span> Cert
              Comply shall have the right to collect from Customer any
              reasonable costs, including attorneys’ fees, incurred in enforcing
              this Agreement.
            </li>
            <li>
              <span className='underline'>Governing Law.</span> This Agreement
              and performance by the parties hereunder shall be construed in
              accordance with the applicable laws of the State of Utah without
              regard to any conflicts of law provision.
            </li>
            <li>
              <span className='underline'>Alternative Dispute Resolution.</span>
              &nbsp;Both parties will attempt to settle any claim or dispute
              arising out of this Agreement through good-faith negotiation. If
              no resolution is reached within 30 days of receipt of written
              notice of the claim or dispute, the parties agree to submit to
              non-binding mediation with a mediator to be selected by mutual
              agreement of the parties. If mediation is unsuccessful, the
              parties agree to submit to binding arbitration in Utah County,
              State of Utah. Such arbitration will be governed in accordance
              with the rules of the American Arbitration Association. Judgment
              of any award may be entered by any court with proper jurisdiction.
              Notwithstanding the foregoing, Cert Comply may seek injunctive or
              other equitable relief to protect its intellectual property rights
              in any court of competent jurisdiction.
            </li>
            <li>
              <span className='underline'>Force Majeure.</span> Any delay or
              failure of either party to perform its obligations under this
              Agreement will be excused to the extent that the delay or failure
              was caused directly by an event beyond such party’s control,
              without such party’s fault or negligence and that by its nature
              could not have been foreseen by such arty or, if it could have
              been foreseen, was unavoidable (which events may include natural
              disasters, acts of God, embargoes, explosions, riots, wars or acts
              of terrorism) (each, a "Force Majeure Event"). The affected party
              shall use best efforts to notify the other party within three
              business days of the Force Majeure Event. Performance under this
              Agreement shall resume when the affected party is able to
              substantially perform that party’s duties.
            </li>
            <li>
              <span className='underline'>Headings.</span> The headings in this
              Agreement are for convenience of reference only and will not
              affect the meaning hereof.
            </li>
          </ol>
        </li>
      </ol>
    </>
  )
}
