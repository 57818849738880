import { SET_AUTH, SET_ROLE, SET_EMAIL_SETTINGS } from 'actions/auth'

const initialState = {
  sub: '',
  email: '',
  firstName: '',
  lastName: '',
  role: null,
  red_flag_notifications: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        ...action.userAttributes,
      }
    case SET_ROLE:
      return {
        ...state,
        role: action.role,
      }
    case SET_EMAIL_SETTINGS:
      return {
        ...state,
        ...action.settings,
      }
    default:
      return state
  }
}
