import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fab, makeStyles, Typography } from '@material-ui/core'
import DefaultTable from 'components/Tables/Default'
import PropTypes from 'prop-types'
import React from 'react'
import CoveragesTable from './Coverages'

const useStyles = makeStyles((theme) => ({
  textCenter: {
    textAlign: 'center',
  },
  bodyNoOrgs: {
    padding: theme.spacing(3, 0),
  },
}))

const ActionsCell = (onDownload) => ({ cell: { value }, row }) => {
  const download = (event) => {
    event.preventDefault()
    onDownload(row.original.id)
  }

  return (
    <div>
      <Fab
        size='small'
        color='primary'
        aria-label='download'
        onClick={download}
      >
        <FontAwesomeIcon icon='cloud-download' className='fa-fw' />
      </Fab>
    </div>
  )
}

function ReviewPoliciesTable({ onDownload, policies }) {
  const classes = useStyles()
  const data = React.useMemo(() => policies, [policies])
  const columns = React.useMemo(
    () => [
      {
        Header: 'POLICY DETAILS',
        Cell: CoveragesTable,
        accessor: 'coverages',
      },
      {
        Header: 'ACTIONS',
        Cell: ActionsCell(onDownload),
      },
    ],
    [onDownload]
  )
  return (
    <div>
      {policies.length === 0 ? (
        <div className={classes.textCenter}>
          <Typography vairant='body1' className={classes.bodyNoOrgs}>
            There aren't any policies
          </Typography>
        </div>
      ) : (
        <DefaultTable columns={columns} data={data} headers={false} />
      )}
    </div>
  )
}

ReviewPoliciesTable.propTypes = {
  onDownload: PropTypes.func.isRequired,
  policies: PropTypes.array.isRequired,
}

export default ReviewPoliciesTable
