import api, { urls } from 'config/api'

/**
 * Action types
 */
export const SET_MEMBERS = 'SET_MEMBERS'
export const UPDATE_MEMBER = 'UPDATE_MEMBER'
export const DELETE_MEMBER = 'DELETE_MEMBER'

/**
 * Action creators
 */
const _setMembers = members => ({
  type: SET_MEMBERS,
  members,
})

const _updateMember = member => ({
  type: UPDATE_MEMBER,
  member,
})

const _deleteMember = id => ({
  type: DELETE_MEMBER,
  id,
})

/**
 * Actions
 */
export const getMembers = orgId => async dispatch => {
  const resp = await api.get(urls.members({ orgId }))
  dispatch(_setMembers(resp.data.results))
}

export const changeMemberRole = (orgId, id, role) => async dispatch => {
  const resp = await api.put(urls.memberRole({ orgId, id }), {
    role,
  })
  dispatch(_updateMember(resp.data))
}

export const deleteMember = (orgId, id) => async dispatch => {
  await api.delete(urls.member({ orgId, id }))
  dispatch(_deleteMember(id))
}
