import {
  REMOVE_DOCUMENT,
  UPDATE_SUBCONTRACTOR_DOCUMENTS,
} from 'actions/documents'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SUBCONTRACTOR_DOCUMENTS:
      return state
        .filter((d) => d.subcontractor !== action.subId)
        .concat(action.documents)
    case REMOVE_DOCUMENT:
      return state.filter((d) => d.id !== action.id)
    default:
      return state
  }
}
