import { Link } from '@material-ui/core'
import DefaultTable from 'components/Tables/Default'
import { DATE_FORMAT } from 'config/constants'
import routes, { formatRoute } from 'config/routes'
import { formatDistanceStrict, parse } from 'date-fns'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { renderLink, toTitleCase } from 'utils'

const SubcontractorCell = (subcontractors) => ({ cell: { value } }) => {
  const subcontractor = subcontractors.find((s) => s.id === value)
  const route = formatRoute(routes.subcontractor, { subId: value })
  return (
    <Link variant='button' color='secondary' component={renderLink(route)}>
      {subcontractor.company_name}
    </Link>
  )
}

const CategoryCell = ({ cell: { value } }) => toTitleCase(value)

const ExpirationCell = ({ cell: { value } }) => {
  const today = new Date()
  const expiration = parse(value, DATE_FORMAT, today)
  return expiration >= today
    ? `Expires in ${formatDistanceStrict(expiration, today, { unit: 'day' })}`
    : 'Expired'
}

function ExpiringPoliciesTable({ coverages, subcontractors }) {
  const data = React.useMemo(() => _.sortBy(coverages, ['expiration_date']), [
    coverages,
  ])
  const columns = React.useMemo(
    () => [
      {
        Header: 'SUBCONTRACTOR',
        accessor: 'subcontractor',
        Cell: SubcontractorCell(subcontractors),
      },
      {
        Header: 'CATEGORY',
        accessor: 'kind',
        Cell: CategoryCell,
      },
      {
        Header: 'EXPIRATION',
        accessor: 'expiration_date',
        Cell: ExpirationCell,
      },
    ],
    [subcontractors]
  )

  return <DefaultTable columns={columns} data={data} headers={false} />
}

ExpiringPoliciesTable.propTypes = {
  coverages: PropTypes.array.isRequired,
}

export default ExpiringPoliciesTable
