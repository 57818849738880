import LoadingWave from '@bit/ngoue.playground.loading-wave'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  makeStyles,
  MenuItem,
  TextField,
  useTheme,
} from '@material-ui/core'
import { API_URL, urls } from 'config/api'
import { useAuthHeader } from 'hooks'
import PropTypes from 'prop-types'
import React from 'react'
import { FilePond } from 'react-filepond'

const DOCUMENT_TYPES = [
  { type: 'w9', display: 'W9' },
  { type: 'agreement', display: 'Subcontractor Agreement' },
  { type: 'ddp', display: 'Distracted Driving Program' },
  { type: 'other', display: 'Other Contract or Document' },
]

const useStyles = makeStyles((theme) => ({
  upload: {
    width: 320,
  },
  documentType: {
    marginBottom: theme.spacing(3),
  },
  emptySelect: {
    opacity: 0.5,
    fontStyle: 'italic',
  },
  loading: {
    marginTop: theme.spacing(3),
  },
  replaceDocumentWarning: {
    color: theme.palette.warning.main,
    fontWeight: 'bold',
  },
}))

function AddDocumentDialog(props) {
  const { onUpload, creating, onClose, ...dialogProps } = props
  const authHeader = useAuthHeader()
  const classes = useStyles()
  const theme = useTheme()
  const [kind, setKind] = React.useState('')

  const handleClose = () => {
    setKind('')
    onClose()
  }

  return (
    <Dialog
      {...dialogProps}
      onClose={handleClose}
      aria-labelledby='dialog-add-document-title'
      aria-describedby='dialog-add-document-description'
    >
      <DialogTitle id='dialog-add-document-title'>Upload Document</DialogTitle>
      <DialogContent>
        <DialogContentText id='dialog-add-document-description'>
          Please upload your document here.
        </DialogContentText>
        {creating ? (
          <LoadingWave
            className={classes.loading}
            primaryColor={theme.palette.primary.main}
            secondaryColor={theme.palette.secondary.main}
          />
        ) : (
          <>
            <FormControl fullWidth className={classes.documentType}>
              <TextField
                select
                id='document-kind'
                label='Document Type'
                value={kind}
                onChange={(event) => setKind(event.target.value)}
                fullWidth
              >
                <MenuItem value='' className={classes.emptySelect}>
                  Select Document Type
                </MenuItem>
                {DOCUMENT_TYPES.map((d) => (
                  <MenuItem value={d.type} key={d.type}>
                    {d.display}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FilePond
              id='upload'
              name='upload'
              className={classes.upload}
              disabled={!kind}
              maxFileSize='25MB'
              acceptedFileTypes={['application/pdf']}
              server={{
                url: API_URL,
                process: urls.upload(),
                revert: urls.upload(),
                fetch: null,
                headers: {
                  Authorization: authHeader,
                },
              }}
              onprocessfile={(error, file) => {
                if (!error) {
                  onUpload({
                    kind,
                    filename: file.filename,
                    upload_id: file.serverId,
                  })
                }
              }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// pass through propTypes
AddDocumentDialog.propTypes = {
  ...Dialog.propTypes,
  onUpload: PropTypes.func.isRequired,
  creating: PropTypes.bool,
}

export default AddDocumentDialog
