import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import {
  createDocument,
  deleteDocument,
  getDocumentDownloadUrl,
  getSubcontractorDocuments,
} from 'actions/documents'
import {
  deletePolicy,
  getPolicyDownloadUrl,
  getSubcontractorPolicies,
} from 'actions/policies'
import {
  deleteSubcontractor,
  getAgreementDownloadUrl,
  getW9DownloadUrl,
  setSubcontractorActive,
} from 'actions/subcontractors'
import Navigation from 'components/Navigation'
import ScreenTitle from 'components/ScreenTitle'
import AddDocumentDialog from 'components/Subcontractors/AddDocumentDialog'
import Info from 'components/Subcontractors/Info'
import DocumentsTable from 'components/Tables/Documents'
import PoliciesTable from 'components/Tables/Policies'
import routes, { formatRoute } from 'config/routes'
import { PermissionsContext, useLocationEffect } from 'hooks'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import CreatePolicy from 'screens/Policies/Create'
import EditPolicy from 'screens/Policies/Edit'
import UploadPolicy from 'screens/Policies/Upload'
import { renderLink } from 'utils'

const useStyles = makeStyles((theme) => ({
  actions: {
    margin: theme.spacing(2, 1),
  },
  documentsContainer: {
    marginBottom: theme.spacing(2),
  },
  btnMargin: {
    marginRight: theme.spacing(1),
  },
  white: {
    color: 'white',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  archiveBtn: {
    float: 'right',
    top: 4,
  },
  archivedMsg: {
    margin: theme.spacing(2, 0),
  },
}))

const DOCUMENT_TYPES = ['w9', 'agreement', 'ddp', 'other']

export const Details = ({
  createDocument,
  deleteDocument,
  currentOrganization,
  deletePolicy,
  deleteSubcontractor,
  documents,
  getAgreementDownloadUrl,
  getDocumentDownloadUrl,
  getPolicyDownloadUrl,
  getSubcontractorDocuments,
  getSubcontractorPolicies,
  getW9DownloadUrl,
  history,
  policies,
  setSubcontractorActive,
  subcontractor,
  ...props
}) => {
  const classes = useStyles()
  const [loadingDocuments, setLoadingDocuments] = React.useState(false)
  const [addingDocument, setAddingDocument] = React.useState(false)
  const [creatingDocument, setCreatingDocument] = React.useState(false)

  useLocationEffect(routes.subcontractor, async ({ params: { subId } }) => {
    setLoadingDocuments(true)
    try {
      await Promise.all([
        getSubcontractorPolicies(currentOrganization.id, subId),
        getSubcontractorDocuments(currentOrganization.id, subId),
      ])
    } finally {
      setLoadingDocuments(false)
    }
  })

  if (!subcontractor) {
    return <Redirect to={routes.subcontractors} />
  }

  const editRoute = formatRoute(routes.subcontractorEdit, {
    subId: subcontractor.id,
  })

  const handleDocumentUpload = async (values) => {
    try {
      setCreatingDocument(true)
      await createDocument(currentOrganization.id, subcontractor.id, values)
    } catch (err) {
      console.error('Error saving document')
      console.error(err)
    } finally {
      setAddingDocument(false)
      setCreatingDocument(false)
    }
  }

  const downloadW9 = async () => {
    const url = await getW9DownloadUrl(
      subcontractor.organization,
      subcontractor.id
    )
    window.open(url, '_blank')
  }

  const downloadAgreement = async () => {
    const url = await getAgreementDownloadUrl(
      subcontractor.organization,
      subcontractor.id
    )
    window.open(url, '_blank')
  }

  const downloadPolicy = async (orgId, id) => {
    const url = await getPolicyDownloadUrl(orgId, id)
    window.open(url, '_blank')
  }

  const downloadDocument = async (orgId, id) => {
    const url = await getDocumentDownloadUrl(orgId, id)
    window.open(url, '_blank')
  }

  const delete_ = async () => {
    const msg = `Are you sure you want to delete ${subcontractor.company_name}? You cannot undo this action.`
    if (window.confirm(msg)) {
      try {
        await deleteSubcontractor(subcontractor.id, subcontractor.organization)
      } catch (err) {
        console.error('Unable to delete subcontractor')
        console.error(err)
      }
    }
  }

  const handleArchive = async () => {
    const msg =
      'When you archive a subcontractor, all the data stays here, but the system will no longer notify you about expiring policies or issues with coverage. You can always unarchive them later.'
    if (window.confirm(msg)) {
      try {
        await setSubcontractorActive(
          currentOrganization.id,
          subcontractor.id,
          false
        )
      } catch (err) {
        console.error('unable to archive')
        console.error(err)
      }
    }
  }

  const handleUnarchive = async () => {
    try {
      await setSubcontractorActive(
        currentOrganization.id,
        subcontractor.id,
        true
      )
    } catch (err) {
      console.error('unable to archive')
      console.error(err)
    }
  }

  return (
    <PermissionsContext.Consumer>
      {(permissions) => (
        <Switch>
          <Route
            exact
            path={routes.policyUpload}
            children={(props) => (
              <UploadPolicy subcontractor={subcontractor} {...props} />
            )}
          />
          <Route
            exact
            path={routes.policyNew}
            children={(props) => (
              <CreatePolicy subcontractor={subcontractor} {...props} />
            )}
          />
          <Route
            exact
            path={routes.policyEdit}
            children={(props) => (
              <EditPolicy
                subcontractor={subcontractor}
                policy={policies.find(
                  (p) => p.id === props.match.params.policyId
                )}
                {...props}
              />
            )}
          />
          <Route path={routes.subcontractor}>
            <Navigation>
              <Container>
                <ScreenTitle>{subcontractor.company_name}</ScreenTitle>
                <div className={classes.actions}>
                  <Fab
                    color='primary'
                    className={classes.btnMargin}
                    component={renderLink(editRoute)}
                  >
                    <FontAwesomeIcon icon='pencil' className='fa-lg' />
                  </Fab>
                  {permissions.canDeleteSubcontractors ? (
                    <Fab
                      color='secondary'
                      className={`${classes.white} ${classes.btnMargin}`}
                      onClick={delete_}
                    >
                      <FontAwesomeIcon icon='trash-alt' className='fa-lg' />
                    </Fab>
                  ) : null}
                  {subcontractor.active ? (
                    <Fab
                      variant='extended'
                      color='primary'
                      className={`${classes.white} ${classes.archiveBtn}`}
                      onClick={handleArchive}
                    >
                      <FontAwesomeIcon
                        icon='archive'
                        className={`fa-lg fa-fw ${classes.btnMargin}`}
                      />
                      Archive
                    </Fab>
                  ) : null}
                </div>
                {!subcontractor.active && (
                  <Alert
                    severity='warning'
                    className={classes.archivedMsg}
                    action={
                      <Button color='inherit' onClick={handleUnarchive}>
                        Unarchive
                      </Button>
                    }
                  >
                    <AlertTitle>Archived</AlertTitle>
                    <Typography>
                      {subcontractor.company_name} has been archived. You will
                      not be notified about expiring policies or issues with
                      coverage.
                    </Typography>
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <Info
                      downloadW9={downloadW9}
                      downloadAgreement={downloadAgreement}
                      {...subcontractor}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} lg={9}>
                    <div className={classes.documentsContainer}>
                      <AddDocumentDialog
                        creating={creatingDocument}
                        open={addingDocument}
                        onClose={() => setAddingDocument(false)}
                        onUpload={handleDocumentUpload}
                      />
                      <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary
                          expandIcon={<FontAwesomeIcon icon='angle-down' />}
                        >
                          <Typography variant='h6'>
                            Documents &amp; Contracts
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.flexColumn}>
                          <DocumentsTable
                            loading={loadingDocuments}
                            onAdd={() => setAddingDocument(true)}
                            onDelete={deleteDocument}
                            onDownload={downloadDocument}
                            subcontractor={subcontractor}
                            documents={documents.filter((document) =>
                              DOCUMENT_TYPES.includes(document.kind)
                            )}
                          />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </div>
                    <div>
                      <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary
                          expandIcon={<FontAwesomeIcon icon='angle-down' />}
                        >
                          <Typography variant='h6'>Policies</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.flexColumn}>
                          <PoliciesTable
                            onDelete={deletePolicy}
                            onDownload={downloadPolicy}
                            subcontractor={subcontractor}
                            policies={policies}
                          />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </Navigation>
          </Route>
        </Switch>
      )}
    </PermissionsContext.Consumer>
  )
}

Details.propTypes = {
  createDocument: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  deletePolicy: PropTypes.func.isRequired,
  deleteSubcontractor: PropTypes.func.isRequired,
  getAgreementDownloadUrl: PropTypes.func.isRequired,
  getDocumentDownloadUrl: PropTypes.func.isRequired,
  getPolicyDownloadUrl: PropTypes.func.isRequired,
  getSubcontractorDocuments: PropTypes.func.isRequired,
  getSubcontractorPolicies: PropTypes.func.isRequired,
  getW9DownloadUrl: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  policies: PropTypes.array.isRequired,
  setSubcontractorActive: PropTypes.func.isRequired,
  subcontractor: PropTypes.object.isRequired,
}

const mapState = (state, props) => ({
  currentOrganization: state.app.currentOrganization,
  documents: state.documents.filter(
    (d) => d.subcontractor === props.match.params.subId
  ),
  policies: state.policies.filter(
    (p) => p.subcontractor === props.match.params.subId
  ),
  subcontractor: state.subcontractors.find(
    (s) => s.id === props.match.params.subId
  ),
})

const mapDispatch = {
  createDocument,
  deleteDocument,
  deletePolicy,
  deleteSubcontractor,
  getAgreementDownloadUrl,
  getDocumentDownloadUrl,
  getPolicyDownloadUrl,
  getSubcontractorDocuments,
  getSubcontractorPolicies,
  getW9DownloadUrl,
  setSubcontractorActive,
}

export default connect(mapState, mapDispatch)(Details)
