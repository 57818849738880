import api, { urls } from 'config/api'

/**
 * Action types
 */
export const SET_SUBCONTRACTORS = 'SET_SUBCONTRACTORS'
export const ADD_SUBCONTRACTOR = 'ADD_SUBCONTRACTOR'
export const UPDATE_SUBCONTRACTOR = 'UPDATE_SUBCONTRACTOR'
export const DELETE_SUBCONTRACTOR = 'DELETE_SUBCONTRACTOR'

/**
 * Action creators
 */
const _setSubcontractors = (subcontractors) => ({
  type: SET_SUBCONTRACTORS,
  subcontractors,
})

const _addSubcontractor = (subcontractor) => ({
  type: ADD_SUBCONTRACTOR,
  subcontractor,
})

const _updateSubcontractor = (subcontractor) => ({
  type: UPDATE_SUBCONTRACTOR,
  subcontractor,
})

const _deleteSubcontractor = (id) => ({
  type: DELETE_SUBCONTRACTOR,
  id,
})

/**
 * Actions
 */
export const getSubcontractor = (orgId, id) => async (dispatch) => {
  const resp = await api.get(urls.subcontractor({ orgId, id }))
  dispatch(_updateSubcontractor(resp.data))
}

export const getSubcontractors = (orgId) => async (dispatch) => {
  const resp = await api.get(urls.subcontractors({ orgId }))
  dispatch(_setSubcontractors(resp.data.results))
}

export const createSubcontractor = (orgId, values) => async (dispatch) => {
  const resp = await api.post(urls.subcontractors({ orgId }), {
    ...values,
  })
  dispatch(_addSubcontractor(resp.data))
  return resp.data.id
}

export const editSubcontractor = (id, orgId, values) => async (dispatch) => {
  const resp = await api.put(urls.subcontractor({ orgId, id }), {
    ...values,
  })
  dispatch(_updateSubcontractor(resp.data))
}

export const deleteSubcontractor = (id, orgId) => async (dispatch) => {
  await api.delete(urls.subcontractor({ orgId, id }))
  dispatch(_deleteSubcontractor(id))
}

export const getW9DownloadUrl = (orgId, id) => async (dispatch) => {
  const resp = await api.get(urls.w9Download({ orgId, id }))
  return resp.data.url
}

export const getAgreementDownloadUrl = (orgId, id) => async (dispatch) => {
  const resp = await api.get(urls.agreementDownload({ orgId, id }))
  return resp.data.url
}

export const setSubcontractorActive = (orgId, id, active) => async (
  dispatch
) => {
  const resp = await api.patch(urls.subcontractor({ orgId, id }), {
    active,
  })
  dispatch(_updateSubcontractor(resp.data))
}
