import {
  ADD_SUBCONTRACTOR,
  DELETE_SUBCONTRACTOR,
  SET_SUBCONTRACTORS,
  UPDATE_SUBCONTRACTOR,
} from 'actions/subcontractors'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBCONTRACTORS:
      return [...action.subcontractors]
    case ADD_SUBCONTRACTOR:
      return [...state, action.subcontractor]
    case UPDATE_SUBCONTRACTOR:
      return state.map(s =>
        s.id === action.subcontractor.id ? action.subcontractor : s
      )
    case DELETE_SUBCONTRACTOR:
      return state.filter(s => s.id !== action.id)
    default:
      return state
  }
}
