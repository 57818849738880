import { Auth } from 'aws-amplify'
import axios from 'axios'
import { compile } from 'path-to-regexp'

const url = (path) => (params) => compile(path)(params)

export const urls = {
  user: url('/v1/user/'),
  upload: url('/v1/upload/'),
  join: url('/v1/join/'),
  coupon: url('/v1/coupon/'),
  organizations: url('/v1/organizations/'),
  organization: url('/v1/organizations/:id/'),
  userRole: url('/v1/organizations/:orgId/userrole/'),
  batchSend: url('/v1/organizations/:orgId/batch_send/'),
  members: url('/v1/organizations/:orgId/members/'),
  member: url('/v1/organizations/:orgId/members/:id/'),
  memberRole: url('/v1/organizations/:orgId/members/:id/role/'),
  invites: url('/v1/organizations/:orgId/invites/'),
  invite: url('/v1/organizations/:orgId/invites/:id/'),
  paymentMethod: url('/v1/organizations/:orgId/payment_method/'),
  subscription: url('/v1/organizations/:orgId/subscription/'),
  subcontractors: url('/v1/organizations/:orgId/subcontractors/'),
  subcontractor: url('/v1/organizations/:orgId/subcontractors/:id/'),
  w9Download: url('/v1/organizations/:orgId/subcontractors/:id/w9/'),
  agreementDownload: url(
    '/v1/organizations/:orgId/subcontractors/:id/agreement/'
  ),
  policies: url('/v1/organizations/:orgId/policies/'),
  policy: url('/v1/organizations/:orgId/policies/:id/'),
  policyDownload: url('/v1/organizations/:orgId/policies/:id/download/'),
  policyFlag: url('/v1/organizations/:orgId/flags/:id/'),
  policyFlagDismiss: url('/v1/organizations/:orgId/flags/:id/dismiss/'),
  documents: url('/v1/organizations/:orgId/documents/'),
  document: url('/v1/organizations/:orgId/documents/:id/'),
  documentDownload: url('/v1/organizations/:orgId/documents/:id/download'),
  tokenSubcontractor: url('/v1/token/subcontractor/'),
  tokenSubcontractorPolicy: url('/v1/token/subcontractor/policy/'),
  tokenSubcontractorDocumentUpload: url('/v1/token/subcontractor/document/'),
  tokenSubcontractorDocumentDetail: url(
    '/v1/token/subcontractor/document/:id/'
  ),
  review: url('/v1/review/'),
  reviewDownload: url('/v1/review/download/'),
}

export const API_URL = process.env.REACT_APP_API_URL

const api = axios.create({
  baseURL: API_URL,
})

api.interceptors.request.use(
  async (config) => {
    // Only include auth token when communicating with the API
    if (config.baseURL === API_URL && !config.headers.Authorization) {
      const session = await Auth.currentSession()
      config.headers.Authorization = `bearer ${session.idToken.jwtToken}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

export default api
