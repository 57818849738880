import { Container } from '@material-ui/core'
import { createSubcontractor } from 'actions/subcontractors'
import EditSubcontractorForm from 'components/Forms/EditSubcontractor'
import Navigation from 'components/Navigation'
import ScreenTitle from 'components/ScreenTitle'
import routes, { formatRoute } from 'config/routes'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

export const Create = props => {
  const [subId, setSubId] = useState()
  const [complete, setComplete] = useState()

  const submit = async values => {
    try {
      const newId = await props.createSubcontractor(
        props.currentOrganization.id,
        values
      )
      setSubId(newId)
      setComplete(true)
    } catch (err) {
      // re-raise for AddSubcontractorForm to handle
      throw err
    }
  }

  const cancel = () => {
    props.history.goBack()
  }

  if (complete) {
    const route = formatRoute(routes.subcontractor, { subId })
    return <Redirect to={route} />
  }

  return (
    <Navigation>
      <Container>
        <ScreenTitle>New Subcontractor</ScreenTitle>
        <EditSubcontractorForm onSubmit={submit} onCancel={cancel} />
      </Container>
    </Navigation>
  )
}

Create.propTypes = {
  createSubcontractor: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
}

const mapState = state => ({
  currentOrganization: state.app.currentOrganization,
})

const mapDispatch = {
  createSubcontractor,
}

export default connect(
  mapState,
  mapDispatch
)(Create)
