import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import Details from './Details'
import WorkTypes from './WorkTypes'

const Info = (props) => {
  return (
    <div>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<FontAwesomeIcon icon='angle-down' />}
        >
          <Typography variant='h6'>Details</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Details {...props} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<FontAwesomeIcon icon='angle-down' />}
        >
          <Typography variant='h6'>Work Types</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <WorkTypes {...props} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

Info.propTypes = {
  company_address_city: PropTypes.string,
  company_address_state: PropTypes.string,
  company_address_street_2: PropTypes.string,
  company_address_street: PropTypes.string,
  company_address_zipcode: PropTypes.string,
  company_fax: PropTypes.string,
  company_name: PropTypes.string.isRequired,
  company_phone: PropTypes.string,
  downloadAgreement: PropTypes.func,
  downloadW9: PropTypes.func,
  has_agreement: PropTypes.bool,
  has_w9: PropTypes.bool,
  id: PropTypes.string.isRequired,
}

export default Info
