/**
 * review.js
 *
 * Actions here are for users who have been granted temporary access to
 * review a selection of subcontractors and policies via the batch send
 * feature.
 */
import api, { urls } from 'config/api'

/**
 * Actions
 */
export const getData = (token) => async () => {
  const resp = await api.get(urls.review(), {
    headers: {
      Authorization: `token ${token}`,
    },
  })
  return resp.data
}

export const getPolicyDownloadUrl = (token, policy_id) => async () => {
  const resp = await api.get(urls.reviewDownload(), {
    params: {
      policy_id,
    },
    headers: {
      Authorization: `token ${token}`,
    },
  })
  return resp.data.url
}
