import React from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from './config/routes'
import Main from './screens/Main'
import Review from './screens/Review'
import Upload from './screens/Upload'

function App(props) {
  return (
    <Switch>
      <Route path={routes.upload} component={Upload} />
      <Route path={routes.review} component={Review} />
      {/**
       * Authenticated User Area
       *
       * Everything that requires a signed-in user goes in <Main/>
       */}
      <Route component={Main} />
    </Switch>
  )
}

export default App
