import { Container } from '@material-ui/core'
import { addInvite, deleteInvite, getInvites } from 'actions/invites'
import { changeMemberRole, deleteMember, getMembers } from 'actions/members'
import LoadingContainer from 'components/LoadingContainer'
import Navigation from 'components/Navigation'
import ScreenTitle from 'components/ScreenTitle'
import TeamTable from 'components/Tables/Team'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

function Team(props) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const load = async () => {
      await Promise.all([
        props.getInvites(props.currentOrganization.id),
        props.getMembers(props.currentOrganization.id),
      ])
      setLoading(false)
    }
    load()
    // eslint-disable-next-line
  }, [])

  const invite = async values => {
    try {
      await props.addInvite(props.currentOrganization.id, values)
    } catch (err) {
      console.error('Unable to create invite')
      console.error(err)
    }
  }

  const deleteInvite = async id => {
    try {
      await props.deleteInvite(props.currentOrganization.id, id)
    } catch (err) {
      console.error('Unable to delete invite')
      console.error(err)
    }
  }

  const changeRole = async (id, role) => {
    try {
      await props.changeMemberRole(props.currentOrganization.id, id, role)
    } catch (err) {
      console.error('Unable to change member role')
      console.error(err)
    }
  }

  const deleteMember = async id => {
    try {
      await props.deleteMember(props.currentOrganization.id, id)
    } catch (err) {
      console.error('Unable to delete member')
      console.error(err)
    }
  }

  return (
    <Navigation>
      <Container>
        <ScreenTitle>Team</ScreenTitle>
        <LoadingContainer loading={loading}>
          <TeamTable
            members={props.members}
            invites={props.invites}
            sendInvite={invite}
            onCancelInvite={deleteInvite}
            onChangeRole={changeRole}
            onRemove={deleteMember}
          />
        </LoadingContainer>
      </Container>
    </Navigation>
  )
}

Team.propTypes = {
  addInvite: PropTypes.func.isRequired,
  changeMemberRole: PropTypes.func.isRequired,
  deleteInvite: PropTypes.func.isRequired,
  deleteMember: PropTypes.func.isRequired,
  getInvites: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
}

const mapState = state => ({
  currentOrganization: state.app.currentOrganization,
  invites: state.invites,
  members: state.members,
})

const mapDispatch = {
  addInvite,
  changeMemberRole,
  deleteInvite,
  deleteMember,
  getInvites,
  getMembers,
}

export default connect(mapState, mapDispatch)(Team)
