import { ADD_ORGANIZATION, SET_ORGANIZATIONS } from 'actions/organizations'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATIONS:
      return [...action.organizations]
    case ADD_ORGANIZATION:
      return [...state, action.organization]
    default:
      return state
  }
}
