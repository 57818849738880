import { RESET_APP } from 'actions'
import { combineReducers } from 'redux'
import app from './app'
import auth from './auth'
import invites from './invites'
import members from './members'
import organizations from './organizations'
import policies from './policies'
import subcontractors from './subcontractors'
import subscription from './subscription'
import documents from './documents'

const appReducer = combineReducers({
  app,
  auth,
  documents,
  invites,
  members,
  organizations,
  policies,
  subcontractors,
  subscription,
})

export default (state, action) => {
  if (action.type === RESET_APP) {
    state = undefined
  }
  return appReducer(state, action)
}
