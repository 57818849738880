import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Link, makeStyles, Typography } from '@material-ui/core'
import DefaultTable from 'components/Tables/Default'
import routes, { formatRoute } from 'config/routes'
import PropTypes from 'prop-types'
import React from 'react'
import { renderLink, toTitleCase } from 'utils'

const useStyles = makeStyles((theme) => ({
  flagIcon: {
    color: theme.palette.error.main,
  },
  message: {
    maxWidth: 850,
  },
}))

const SubcontractorCell = (classes) => ({ cell: { value } }) => {
  const route = formatRoute(routes.subcontractor, { subId: value.id })
  return (
    <>
      <Link variant='button' color='secondary' component={renderLink(route)}>
        {value.company_name}
      </Link>
    </>
  )
}

const ActionsCell = (onDismiss) => ({ cell: { value }, row }) => {
  const dismiss = () => {
    const msg =
      'Please make sure you reviewed this issue with your insurance agent. This alert will not appear again.'
    if (window.confirm(msg)) {
      onDismiss(row.original.subcontractor.id, value)
    }
  }

  return (
    <Button variant='text' color='secondary' onClick={dismiss}>
      DISMISS
    </Button>
  )
}

function AlertsTable({ alerts, onDismiss }) {
  const classes = useStyles()
  const data = React.useMemo(() => alerts, [alerts])
  const columns = React.useMemo(
    () => [
      {
        id: 'flag',
        accessor: () => (
          <FontAwesomeIcon
            icon='flag'
            className={`fa-fw fa-lg ${classes.flagIcon}`}
            style={{ marginRight: '8px' }}
          />
        ),
      },
      {
        Header: 'SUBCONTRACTOR',
        accessor: 'subcontractor',
        Cell: SubcontractorCell(classes),
      },
      {
        Header: 'CODE',
        accessor: (row) => <Typography>{toTitleCase(row.code)}</Typography>,
      },
      {
        Header: 'DESCRIPTION',
        accessor: (row) => <Typography>{row.description}</Typography>,
      },
      {
        Header: 'ACTIONS',
        accessor: 'id',
        Cell: ActionsCell(onDismiss),
      },
    ],
    [classes, onDismiss]
  )

  return <DefaultTable columns={columns} data={data} />
}

AlertsTable.propTypes = {
  alerts: PropTypes.array.isRequired,
  onDismiss: PropTypes.func.isRequired,
}

export default AlertsTable
