import { LinearProgress, Link, makeStyles, Typography } from '@material-ui/core'
import UploadPolicyForm from 'components/Forms/UploadPolicy'
import PropTypes from 'prop-types'
import React from 'react'

const MIN_TRIES = 5
const MAX_TRIES = 20

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  loadingBar: {
    margin: theme.spacing(4, 'auto'),
    height: theme.spacing(1),
    maxWidth: '90%',
  },
}))

function PolicyUpload({ authHeader, getDocument, onFinish, onUpload }) {
  const classes = useStyles()
  const [processing, setProcessing] = React.useState(false)
  const [documentId, setDocumentId] = React.useState()
  const [tries, setTries] = React.useState(0)

  React.useEffect(() => {
    let interval
    if (processing && documentId && tries <= MAX_TRIES) {
      interval = setInterval(async () => {
        try {
          setTries((state) => state + 1)
          // wait at least 15 seconds before we start checking
          if (tries < MIN_TRIES) return
          const data = await getDocument(documentId)
          if (data.job_status === 'SUCCEEDED') {
            const {
              process_result: { insurers = [], coverage = [{}] },
            } = data
            const policy = {
              document: documentId,
              coverages: coverage.map(({ carrier, ...other }) => ({
                ...other,
                carrier: insurers.find((i) => i.id === carrier)?.name,
              })),
            }
            setProcessing(false)
            onFinish(policy)
          }
        } catch (err) {
          console.error('error processing document')
          console.error(err)
        }
      }, 3000)
    } else if (documentId && tries >= MAX_TRIES) {
      const policy = { document: documentId }
      onFinish(policy)
    }

    return () => {
      clearInterval(interval)
    }
  }, [documentId, getDocument, processing, tries, onFinish])

  const upload = async (file) => {
    try {
      setProcessing(true)
      const resp = await onUpload(file)
      setDocumentId(resp.id)
    } catch (err) {
      setProcessing(false)
      console.error('error uploading policy')
      console.error(err)
    }
  }

  return (
    <div>
      {processing ? (
        <div className={classes.center}>
          <Typography>We are processing your upload</Typography>
          <LinearProgress
            className={classes.loadingBar}
            color='secondary'
            variant={!!documentId ? 'determinate' : 'indeterminate'}
            value={(tries / MAX_TRIES) * 100}
          />
          {documentId && (
            <Typography>
              <Link
                color='secondary'
                href='#'
                onClick={(event) => {
                  event.preventDefault()
                  onFinish({ document: documentId })
                }}
              >
                Skip this step, I'll enter the info manually.
              </Link>
            </Typography>
          )}
        </div>
      ) : (
        <UploadPolicyForm onUpload={upload} authHeader={authHeader} />
      )}
    </div>
  )
}

PolicyUpload.propTypes = {
  authHeader: PropTypes.string,
  getDocument: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
}

export default PolicyUpload
