import { Typography } from '@material-ui/core'
import { API_URL, urls } from 'config/api'
import { useAuthHeader } from 'hooks'
import React from 'react'
import { FilePond } from 'react-filepond'

function UploadPolicy(props) {
  const { onUpload } = props
  const authHeader = useAuthHeader(props.authHeader)
  return (
    <div>
      <Typography gutterBottom>
        Please upload a copy of the certificate of insurance
      </Typography>
      <FilePond
        id='upload'
        name='upload'
        maxFileSize='25MB'
        acceptedFileTypes={['application/pdf']}
        server={{
          url: API_URL,
          process: urls.upload(),
          revert: urls.upload(),
          fetch: null,
          headers: {
            Authorization: authHeader,
          },
        }}
        onprocessfile={(error, file) => {
          if (!error) {
            onUpload(file)
          }
        }}
      />
    </div>
  )
}

export default UploadPolicy
