import {
  ADD_POLICY,
  REMOVE_AI_DOCUMENT,
  REMOVE_POLICY,
  SET_POLICIES,
  UPDATE_POLICIES,
  UPDATE_POLICY,
} from 'actions/policies'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_POLICIES:
      return [...action.policies]
    case ADD_POLICY:
      return [...state, action.policy]
    case UPDATE_POLICIES:
      const newState = [...state]
      // eslint-disable-next-line
      for (let policy of action.policies) {
        const index = newState.findIndex((p) => p.id === policy.id)
        if (index >= 0) {
          newState[index] = policy
        } else {
          newState.push(policy)
        }
      }
      return newState
    case UPDATE_POLICY:
      return state.map((p) => (p.id === action.policy.id ? action.policy : p))
    case REMOVE_POLICY:
      return state.filter((p) => p.id !== action.id)
    case REMOVE_AI_DOCUMENT:
      return state.map((p) =>
        p.id !== action.policyId
          ? p
          : {
              ...p,
              additional_insured_documents: p.additional_insured_documents.filter(
                (ai) => ai.id !== action.id
              ),
            }
      )
    default:
      return state
  }
}
