import Amplify from 'aws-amplify'
import awsconfig from '../aws-exports'

// Configure SDK at startup
Amplify.configure(awsconfig)

// Configure authenticator
const usernameAttributes = 'email'

const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: 'Email',
      placeholder: 'Email',
      key: 'email',
      required: true,
      displayOrder: 1,
      type: 'string',
    },
    {
      label: 'Password',
      placeholder: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password',
    },
  ],
}

const theme = {
  button: { backgroundColor: '#151515' },
  toast: { backgroundColor: '#151515' },
  a: { color: '#00acee' },
}

export const authConfig = {
  usernameAttributes,
  signUpConfig,
  theme,
}
