import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import LoadingWave from 'components/LoadingWave'
import PropTypes from 'prop-types'
import React from 'react'

function BatchSendDialog({ subcontractors, onSend, ...dialogProps }) {
  const [email, setEmail] = React.useState('')
  const [sending, setSending] = React.useState(false)
  const isValidEmail = email.match(/.*@.*/)
  const handleEmail = (event) => setEmail(event.target.value)
  const send = async () => {
    setSending(true)
    const sent = await onSend(email)
    sent && setEmail('')
    setSending(false)
  }
  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Batch Send</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The Batch Send feature will generate a link with access to all
          policies within the last two years for the following subcontractors:
        </DialogContentText>
        <ul>
          {subcontractors.map((s) => (
            <li key={s.id}>
              <DialogContentText>{s.company_name}</DialogContentText>
            </li>
          ))}
        </ul>
        {sending ? (
          <LoadingWave />
        ) : (
          <TextField
            fullWidth
            name='email'
            label='Email'
            type='email'
            value={email}
            onChange={handleEmail}
            autoComplete='off'
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={send}
          disabled={!isValidEmail || sending}
        >
          {sending ? 'Sending' : 'Send'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

BatchSendDialog.propTypes = {
  ...Dialog.propTypes,
  subcontractors: PropTypes.array.isRequired,
  onSend: PropTypes.func.isRequired,
}

export default BatchSendDialog
