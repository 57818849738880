import { Link } from '@material-ui/core'
import DefaultTable from 'components/Tables/Default'
import routes, { formatRoute } from 'config/routes'
import PropTypes from 'prop-types'
import React from 'react'
import { renderLink } from 'utils'

const SubcontractorCell = ({ cell: { value }, row }) => {
  const route = formatRoute(routes.subcontractor, { subId: value })
  return (
    <Link variant='button' color='secondary' component={renderLink(route)}>
      {row.original.company_name}
    </Link>
  )
}

const MissingInfoCell = ({ cell: { value: subcontractor } }) => {
  const missing = []
  if (!subcontractor.has_w9) missing.push('W9')
  if (!subcontractor.has_agreement) missing.push('Agreement')
  if (!subcontractor.has_policy) missing.push('COI')
  return `Missing ${missing.slice(0, missing.length - 1).join(', ')}${
    missing.length > 1 ? ' and ' : ''
  }${missing[missing.length - 1]}`
}

function SubcontractorsMissingInfoTable({ subcontractors }) {
  const data = React.useMemo(() => subcontractors, [subcontractors])
  const columns = React.useMemo(
    () => [
      {
        Header: 'SUBCONTRACTOR',
        accessor: 'id',
        Cell: SubcontractorCell,
      },
      {
        Header: 'MISSING',
        accessor: (row) => row,
        Cell: MissingInfoCell,
      },
    ],
    []
  )

  return <DefaultTable columns={columns} data={data} headers={false} />
}

SubcontractorsMissingInfoTable.propTypes = {
  subcontractors: PropTypes.array.isRequired,
}

export default SubcontractorsMissingInfoTable
