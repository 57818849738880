import { DELETE_MEMBER, SET_MEMBERS, UPDATE_MEMBER } from 'actions/members'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MEMBERS:
      return [...action.members]
    case UPDATE_MEMBER:
      return state.map(m => (m.id === action.member.id ? action.member : m))
    case DELETE_MEMBER:
      return state.filter(m => m.id !== action.id)
    default:
      return state
  }
}
