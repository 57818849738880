import { DATE_FORMAT } from 'config/constants'
import { format, parse } from 'date-fns'
import React from 'react'
import { toTitleCase } from 'utils'
import DefaultTable from './Default'

function CoveragesTable({ cell: { value: coverages } }) {
  const data = React.useMemo(() => coverages, [coverages])
  const columns = React.useMemo(
    () => [
      {
        Header: 'CARRIER',
        accessor: 'carrier',
      },
      {
        Header: 'TYPE',
        accessor: (row) => toTitleCase(row.kind),
      },
      {
        Header: 'EXPIRES',
        accessor: (row) => {
          const parsed = parse(row.expiration_date, DATE_FORMAT, new Date())
          return format(parsed, 'MM/dd/yyyy')
        },
      },
    ],
    []
  )
  return (
    <DefaultTable columns={columns} data={data} className='coverages-table' />
  )
}

export default CoveragesTable
