import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Drawer, Hidden, makeStyles } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { logout, performStartup } from 'actions'
import { selectCurrentOrganization } from 'actions/organizations'
import routes from 'config/routes'
import { darkTheme } from 'config/theme'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Menu from './Menu'

const drawerWidth = 260

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    width: drawerWidth,
  },
  menuButtonContainer: {
    marginTop: '10px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      height: '100%',
    },
  },
}))

function Navigation(props) {
  const {
    currentOrganization,
    email,
    logout,
    organizations,
    performStartup,
    selectCurrentOrganization,
  } = props
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [orgChanged, setOrgChanged] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const selectOrganization = async id => {
    if (id !== currentOrganization.id) {
      setOrgChanged(true)
      await selectCurrentOrganization(id)
      await performStartup()
    }
  }

  if (orgChanged) {
    return <Redirect to={routes.dashboard} />
  }

  const menu = (
    <Menu
      email={email}
      logout={logout}
      organizations={organizations}
      currentOrganization={currentOrganization}
      selectOrganization={selectOrganization}
    />
  )

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <nav className={classes.drawer} aria-label='main navigation'>
          <Hidden mdUp>
            <Drawer
              variant='temporary'
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {menu}
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Drawer
              variant='permanent'
              open
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {menu}
            </Drawer>
          </Hidden>
        </nav>
      </ThemeProvider>

      <div className={classes.menuButtonContainer}>
        <Button onClick={handleDrawerToggle}>
          <FontAwesomeIcon icon='bars' className='fa-2x' />
        </Button>
      </div>

      <div className={classes.content}>{props.children}</div>
    </>
  )
}

Navigation.propTypes = {
  currentOrganization: PropTypes.object,
  email: PropTypes.string.isRequired,
  organizations: PropTypes.array.isRequired,
}

const mapState = state => ({
  currentOrganization: state.app.currentOrganization,
  email: state.auth.email,
  organizations: state.organizations,
})

const mapDispatch = {
  logout,
  performStartup,
  selectCurrentOrganization,
}

export default connect(
  mapState,
  mapDispatch
)(Navigation)
