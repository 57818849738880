import api, { urls } from 'config/api'

/**
 * Action types
 */
export const SET_POLICIES = 'SET_POLICIES'
export const UPDATE_POLICIES = 'UPDATE_POLICIES'
export const ADD_POLICY = 'ADD_POLICY'
export const UPDATE_POLICY = 'UPDATE_POLICY'
export const REMOVE_POLICY = 'REMOVE_POLICY'
export const REMOVE_AI_DOCUMENT = 'REMOVE_AI_DOCUMENT'

/**
 * Action creators
 */
const _setPolicies = (policies) => ({
  type: SET_POLICIES,
  policies,
})

const _updatePolicies = (policies) => ({
  type: UPDATE_POLICIES,
  policies,
})

const _addPolicy = (policy) => ({
  type: ADD_POLICY,
  policy,
})

const _updatePolicy = (policy) => ({
  type: UPDATE_POLICY,
  policy,
})

const _removePolicy = (id) => ({
  type: REMOVE_POLICY,
  id,
})

const _removeAdditionalInsuredDocument = (policyId, id) => ({
  type: REMOVE_AI_DOCUMENT,
  policyId,
  id,
})

/**
 * Actions
 */
export const getPolicies = (orgId) => async (dispatch) => {
  const resp = await api.get(urls.policies({ orgId }))
  dispatch(_setPolicies(resp.data.results))
}

export const getSubcontractorPolicies = (orgId, subId) => async (dispatch) => {
  const resp = await api.get(urls.policies({ orgId }), {
    params: {
      subcontractor_id: subId,
    },
  })
  dispatch(_updatePolicies(resp.data.results))
}

export const createPolicy = (orgId, subId, values) => async (dispatch) => {
  const resp = await api.post(urls.policies({ orgId }), {
    ...values,
    subcontractor: subId,
  })
  dispatch(_addPolicy(resp.data))
}

export const editPolicy = (id, subId, orgId, values) => async (dispatch) => {
  const resp = await api.put(urls.policy({ orgId, subId, id }), {
    ...values,
  })
  dispatch(_updatePolicy(resp.data))
}

export const deletePolicy = (orgId, id) => async (dispatch) => {
  await api.delete(urls.policy({ orgId, id }))
  dispatch(_removePolicy(id))
}

export const getPolicyDownloadUrl = (orgId, id) => async (dispatch) => {
  const resp = await api.get(urls.policyDownload({ orgId, id }))
  return resp.data.url
}

export const dismissPolicyFlag = (orgId, subId, id) => async (dispatch) => {
  await api.post(urls.policyFlagDismiss({ orgId, id }))
  await dispatch(getSubcontractorPolicies(orgId, subId))
}

export const removeAdditionalInsuredDocument = (policyId, id) => async (
  dispatch
) => {
  dispatch(_removeAdditionalInsuredDocument(policyId, id))
}
