import {
  Button,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import InviteMemberForm from 'components/Forms/InviteMember'
import DefaultTable from 'components/Tables/Default'
import { ROLES } from 'config/constants'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(5),
  },
  helpText: {
    marginTop: theme.spacing(2),
  },
}))

const InviteActionsCell = onCancel => ({ cell: { value }, row }) => {
  const cancel = () => {
    onCancel(row.original.id)
  }

  return (
    <div>
      <Button color='secondary' onClick={cancel}>
        Cancel
      </Button>
    </div>
  )
}

const ChangeMemberRoleActionCell = onChangeRole => ({
  cell: { value },
  row,
}) => {
  if (row.original.role === ROLES.owner) {
    return null
  }

  const newRole = row.original.role === ROLES.admin ? ROLES.member : ROLES.admin

  const update = () => {
    onChangeRole(row.original.id, newRole)
  }

  return (
    <div>
      <Button color='secondary' onClick={update}>
        {`Change to ${newRole}`}
      </Button>
    </div>
  )
}

const RemoveMemberActionCell = onRemove => ({ cell: { value }, row }) => {
  if (row.original.role === ROLES.owner) {
    return null
  }

  const remove = () => {
    onRemove(row.original.id)
  }

  return (
    <div>
      <Button color='secondary' onClick={remove}>
        Remove
      </Button>
    </div>
  )
}

function TeamTable({
  invites,
  members,
  onCancelInvite,
  onChangeRole,
  onRemove,
  sendInvite,
}) {
  const classes = useStyles()
  const inviteData = React.useMemo(() => invites, [invites])
  const inviteColumns = React.useMemo(
    () => [
      {
        Header: 'EMAIL',
        accessor: 'email',
      },
      {
        Header: 'ROLE',
        accessor: 'role',
      },
      {
        Header: 'ACTIONS',
        Cell: InviteActionsCell(onCancelInvite),
      },
    ],
    [onCancelInvite]
  )
  const memberData = React.useMemo(() => members, [members])
  const memberColumns = React.useMemo(
    () => [
      {
        Header: 'EMAIL',
        accessor: 'user',
      },
      {
        Header: 'ROLE',
        accessor: 'role',
      },
      {
        Header: 'ROLE ACTIONS',
        Cell: ChangeMemberRoleActionCell(onChangeRole),
      },
      {
        Header: 'MEMBER ACTIONS',
        Cell: RemoveMemberActionCell(onRemove),
      },
    ],
    [onChangeRole, onRemove]
  )

  const submit = values => {
    sendInvite(values)
  }

  return (
    <Card>
      <CardContent>
        <Typography variant='h6'>Invite</Typography>
        <Typography variant='body1'>
          Add additional users to your organization.
        </Typography>
        <InviteMemberForm onSubmit={submit} />
        {invites.length > 0 ? (
          <>
            <Typography variant='h6' className={classes.section}>
              Pending Invitations
            </Typography>
            <DefaultTable
              columns={inviteColumns}
              data={inviteData}
              headers={false}
            />
          </>
        ) : null}
        <Typography variant='h6' className={classes.section}>
          Members
        </Typography>
        <DefaultTable
          columns={memberColumns}
          data={memberData}
          headers={false}
        />
        <Typography variant='body2' className={classes.helpText}>
          <strong>IMPORTANT:</strong> Users listed here have access to view and
          edit any data that belongs to your organization. Only admins and
          owners can invite new users.
        </Typography>
      </CardContent>
    </Card>
  )
}

TeamTable.propTypes = {
  invites: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
  onCancelInvite: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  sendInvite: PropTypes.func.isRequired,
}

export default TeamTable
