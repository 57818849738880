import { AsYouType } from 'libphonenumber-js'
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Sleep for specified number of milliseconds
 *
 * Helpful for testing async/await blocks or for guaranteeting that a
 * promise or group of promises takes a minimum amount of time to
 * resolve.
 */
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Utility function for using react-router-dom links within Material UI
 * https://material-ui.com/guides/composition/#link
 */
export const renderLink = (to) =>
  React.forwardRef((props, ref) => <Link to={to} {...props} innerRef={ref} />)

/**
 * Convert a snake case string to title case
 */
export const toTitleCase = (str) => {
  return str
    .split('_')
    .map((part) => {
      if (['and', 'or'].includes(part)) {
        return part
      }
      const letters = part.split('')
      return letters.length > 0 ? letters[0].toUpperCase() + letters.slice(1).join('') : ''
    })
    .join(' ')
}

/**
 * Format phone numbers (incomplete and complete)
 */
export const formatNumber = (number) => {
  return number.replace(/\D/, '').length > 3
    ? new AsYouType('US').input(number)
    : number
}

/**
 * Pretty List
 */
export const prettyList = (arr) => arr.map((x) => toTitleCase(x)).join(', ')

/**
 * Icon for Credit Card
 *
 * Takes a card brand (see supported brands in Stripe) and returns
 * a FontAwesome icon name if found.
 */
export const iconForCc = (brand) => {
  const brands = {
    'american express': 'cc-amex',
    'diners club': 'cc-diners-club',
    discover: 'cc-discover',
    jcb: 'cc-jcb',
    mastercard: 'cc-mastercard',
    visa: 'cc-visa',
  }

  return brands[brand.toLowerCase()]
}
