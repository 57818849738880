import { Card, CardContent } from '@material-ui/core'
import {
  CardElement as StripeCardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { savePayment, updateSubscription } from 'actions/subscription'
import PaymentMethod from 'components/Subscription/PaymentMethod'
import PlanDetails from 'components/Subscription/PlanDetails'
import Status from 'components/Subscription/Status'
import routes from 'config/routes'
import { useCommonStyles } from 'hooks'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

function Details(props) {
  const { subscription } = props
  const [updatingSubscription, setUpdatingSubscription] = React.useState(false)
  const [updatingPayment, setUpdatingPayment] = React.useState(false)
  const [savingPayment, setSavingPayment] = React.useState(false)
  const [paymentError, setPaymentError] = React.useState()
  const commonClasses = useCommonStyles()
  const stripe = useStripe()
  const elements = useElements()

  const savePaymentMethod = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return
    }

    setSavingPayment(true)
    setPaymentError(null)
    try {
      const cardElement = elements.getElement(StripeCardElement)
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })

      if (error) {
        setPaymentError(error.message)
        setSavingPayment(false)
        console.error('[error]', error)
        return
      }

      try {
        await props.savePayment(paymentMethod.id)
        setUpdatingPayment(false)
      } catch (err) {
        setPaymentError(
          'We were unable to save your payment method. Check your card info and try again or try a different card.'
        )
        console.error('error updating payment')
        console.error(err)
      }
    } catch (err) {
      console.error('error retrieving card element')
      console.error(err)
    } finally {
      setSavingPayment(false)
    }
  }

  const updateSubscription = async (cancel) => {
    setUpdatingSubscription(true)
    try {
      await props.updateSubscription(cancel)
    } catch (err) {
      console.error('error updating subscription')
      console.error(err)
    } finally {
      setUpdatingSubscription(false)
    }
  }

  const cancelEditPayment = () => {
    setPaymentError(null)
    setSavingPayment(false)
    setUpdatingPayment(false)
  }

  if (!subscription) {
    return <Redirect to={routes.subscriptionNew} />
  }

  return (
    <Card className={commonClasses.mw600}>
      <CardContent>
        <PlanDetails subscription={subscription} />
        <hr className={commonClasses.hr} />
        <PaymentMethod
          card={
            subscription.customer.invoice_settings.default_payment_method.card
          }
          editing={updatingPayment}
          error={paymentError}
          isActive={
            (subscription.status === 'active' &&
              !subscription.cancel_at_period_end) ||
            subscription.status === 'past_due'
          }
          onCancel={cancelEditPayment}
          onEdit={() => setUpdatingPayment(true)}
          onUpdate={savePaymentMethod}
          updating={savingPayment}
        />
        <hr className={commonClasses.hr} />
        <Status
          onCancel={() => updateSubscription(true)}
          onResume={() => updateSubscription(false)}
          subscription={subscription}
          updating={updatingSubscription}
        />
      </CardContent>
    </Card>
  )
}

Details.propTypes = {
  savePayment: PropTypes.func.isRequired,
  subscription: PropTypes.object,
  updateSubscription: PropTypes.func.isRequired,
}

const mapDispatch = {
  savePayment,
  updateSubscription,
}

export default connect(null, mapDispatch)(Details)
