import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Form as FormikForm, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { UsaStates } from 'usa-states'
import { formatNumber, toTitleCase } from 'utils'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
  },
  header: {
    marginTop: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  fill: {
    width: '100%',
  },
  workTypeContainer: {
    margin: theme.spacing(3, 0),
  },
  chip: {
    margin: theme.spacing(0.5),
    color: 'white',
    fontWeight: 'bold',
  },
  resultsPaper: {
    boxShadow: '1px 1px 10px 1px rgba(0,0,0,0.3)',
    backgroundColor: '#fafafa',
  },
  error: {
    color: 'red',
  },
}))

const contractTypes = [
  'apartments',
  'blasting',
  'boilers',
  'bridges_overpass',
  'building_demolition',
  'carpentry',
  'concrete_foundation',
  'conduit_construction',
  'dams_or_levees',
  'drilling',
  'driveway_paving',
  'earthquake_repair',
  'electrical',
  'elevator_or_escalator',
  'environmental',
  'excavation',
  'gas_mains',
  'grading',
  'HVAC',
  'insulation',
  'janitorial',
  'liquid_petroleum_gas',
  'maintenance',
  'masonry',
  'mechanical',
  'ornamental_steel',
  'pad_compaction',
  'painting',
  'plastering',
  'plumbing',
  'roofing',
  'seismic_retrofitting',
  'sewer_or_water_lines',
  'sign_manufacturing_and_installation',
  'streets_or_roads',
  'structural_steel',
  'supervisory_only',
  'swimming_pool_construction',
  'tile_marble_or_terrazzo',
  'town_homes',
  'tract_housing',
  'traffic_control',
  'traffic_signal_or_overhead_lines',
]

const EditSubcontractorForm = (props) => {
  const classes = useStyles()
  const states = new UsaStates().arrayOf('abbreviations')

  const initial = {
    company_name: '',
    company_phone: '',
    company_fax: '',
    company_address_street_1: '',
    company_address_street_2: '',
    company_address_city: '',
    company_address_state: '',
    company_address_zipcode: '',
    primary_contact_email: '',
    primary_contact_first_name: '',
    primary_contact_last_name: '',
    primary_contact_phone: '',
    residential: false,
    commercial: false,
    industrial: false,
    contract_types: [],
    ...props.subcontractor,
  }

  const validate = (values) => {
    const errors = {}
    const requiredFields = ['company_name', 'primary_contact_email']
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Required'
      }
    })
    if (!values.residential && !values.commercial && !values.industrial) {
      errors.general_work_type =
        'Please specify at least one category of work that will be performed by this subcontractor'
    }
    if (values.contract_types.length <= 0) {
      errors.contract_types =
        'Please specify at least one type of work that will be performed by this subcontractor'
    }
    return errors
  }

  const submit = async (values, actions) => {
    const {
      company_phone,
      company_fax,
      primary_contact_phone,
      ...rest
    } = values

    let processed = {
      ...rest,
      company_phone: company_phone.replace(/\D/g, ''),
      company_fax: company_fax.replace(/\D/g, ''),
      primary_contact_phone: primary_contact_phone.replace(/\D/g, ''),
    }

    try {
      await props.onSubmit(processed)
    } catch (err) {
      console.error('Error saving subcontractor')
      console.error(err)
    }

    actions.setSubmitting(false)
  }

  return (
    <Card className={classes.card}>
      <Formik
        enableReinitialize
        initialValues={initial}
        onReset={props.onCancel}
        onSubmit={submit}
        validate={validate}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleReset,
          isSubmitting,
          isValid,
          touched,
          setFieldValue,
          setFieldTouched,
          values,
        }) => (
          <FormikForm>
            <CardContent>
              <TextField
                className={classes.input}
                fullWidth
                id='company_name'
                name='company_name'
                label='Company Name *'
                value={values.company_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.company_name && !!errors.company_name}
                helperText={
                  touched.company_name && !!errors.company_name
                    ? errors.company_name
                    : ''
                }
              />
              <TextField
                className={classes.input}
                fullWidth
                id='company_phone'
                name='company_phone'
                label='Phone Number'
                value={formatNumber(values.company_phone)}
                onChange={handleChange}
              />
              <TextField
                className={classes.input}
                fullWidth
                id='company_fax'
                name='company_fax'
                label='Fax Number'
                value={formatNumber(values.company_fax)}
                onChange={handleChange}
              />
              <Typography variant='h6' className={classes.header}>
                Address
              </Typography>
              <TextField
                className={classes.input}
                fullWidth
                id='company_address_street_1'
                name='company_address_street_1'
                label='Street Address'
                value={values.company_address_street_1}
                onChange={handleChange}
              />
              <TextField
                className={classes.input}
                fullWidth
                id='company_address_street_2'
                name='company_address_street_2'
                label='Apt/Suite/Other'
                value={values.company_address_street_2}
                onChange={handleChange}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    className={classes.input}
                    fullWidth
                    id='company_address_city'
                    name='company_address_city'
                    label='City'
                    value={values.company_address_city}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormControl className={classes.fill}>
                    <InputLabel id='company_address_state_label'>
                      State
                    </InputLabel>
                    <Select
                      id='company_address_state'
                      name='company_address_state'
                      labelid='company_address_state_label'
                      value={values.company_address_state}
                      onChange={handleChange}
                    >
                      {states.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8} md={4}>
                  <TextField
                    className={classes.input}
                    fullWidth
                    id='company_address_zipcode'
                    name='company_address_zipcode'
                    label='Zip Code'
                    value={values.company_address_zipcode}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Typography variant='h6' className={classes.header}>
                Primary Contact
              </Typography>
              <TextField
                className={classes.input}
                fullWidth
                id='primary_contact_email'
                name='primary_contact_email'
                label='Email *'
                value={values.primary_contact_email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.primary_contact_email &&
                  !!errors.primary_contact_email
                }
                helperText={
                  touched.primary_contact_email &&
                  !!errors.primary_contact_email
                    ? errors.primary_contact_email
                    : ''
                }
              />
              <TextField
                className={classes.input}
                fullWidth
                id='primary_contact_first_name'
                name='primary_contact_first_name'
                label='First Name'
                value={values.primary_contact_first_name}
                onChange={handleChange}
              />
              <TextField
                className={classes.input}
                fullWidth
                id='primary_contact_last_name'
                name='primary_contact_last_name'
                label='Last Name'
                value={values.primary_contact_last_name}
                onChange={handleChange}
              />
              <TextField
                className={classes.input}
                fullWidth
                id='primary_contact_phone'
                name='primary_contact_phone'
                label='Phone Number'
                value={formatNumber(values.primary_contact_phone)}
                onChange={handleChange}
              />
              <Typography variant='h6' className={classes.header}>
                Subcontractor Work Details
              </Typography>
              <Typography variant='body1'>
                Check the boxes below to indicate the <strong>general</strong>{' '}
                type of work your subcontractor will be performing
              </Typography>
              {errors.general_work_type &&
                (touched.residential ||
                  touched.commercial ||
                  touched.industrial) && (
                  <Typography variant='subtitle1' className={classes.error}>
                    Please specify the general type of work performed by this
                    subcontractor.
                  </Typography>
                )}
              <Grid container spacing={2} className={classes.workTypeContainer}>
                {['residential', 'commercial', 'industrial'].map((type) => (
                  <Grid key={type} item xs={12} md={6}>
                    <FormControlLabel
                      label={toTitleCase(type)}
                      control={
                        <Checkbox
                          name={type}
                          checked={values[type]}
                          onChange={(event) => {
                            handleChange(event)
                            setFieldTouched(type, true)
                          }}
                          value={type}
                        />
                      }
                    />
                  </Grid>
                ))}
              </Grid>
              <Typography variant='body1'>
                Use the field below to indicate the <strong>specific</strong>{' '}
                type of work your subcontractor will be performing. Select from
                our list of work types or enter your own.
              </Typography>
              <div className={classes.workTypeContainer}>
                <Autocomplete
                  onChange={(event, value) => {
                    setFieldValue(
                      'contract_types',
                      value.map((v) => v.replace(/\s/g, '_').toLowerCase())
                    )
                    setFieldTouched('contract_types', true)
                  }}
                  onBlur={() => setFieldTouched('contract_types', true)}
                  value={values.contract_types}
                  multiple
                  freeSolo
                  disableCloseOnSelect
                  classes={{ paper: classes.resultsPaper }}
                  id='contractTypes'
                  options={contractTypes}
                  getOptionLabel={(option) => toTitleCase(option)}
                  ChipProps={{ color: 'secondary', className: classes.chip }}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {toTitleCase(option)}
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Work Types'
                      placeholder='Work Types'
                    />
                  )}
                />
              </div>
              {errors.contract_types && touched.contract_types && (
                <Typography variant='subtitle1' className={classes.error}>
                  Please indicate the specific type of work performed by this
                  subcontractor.
                </Typography>
              )}
            </CardContent>
            <CardActions>
              <Button
                type='submit'
                disabled={!dirty || !isValid || isSubmitting}
                variant={dirty ? 'contained' : null}
                color={dirty ? 'primary' : 'secondary'}
              >
                Save
              </Button>
              <Button disabled={isSubmitting} onClick={handleReset}>
                Cancel
              </Button>
            </CardActions>
          </FormikForm>
        )}
      </Formik>
    </Card>
  )
}

EditSubcontractorForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  subcontractor: PropTypes.object,
}

EditSubcontractorForm.defaultProps = {
  subcontractor: {},
}

export default EditSubcontractorForm
