import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: theme.spacing(3),
  },
}))

function ScreenTitle(props) {
  const classes = useStyles()
  return (
    <Typography
      variant='h4'
      component='h1'
      className={classes.title}
      gutterBottom
    >
      {props.children}
    </Typography>
  )
}

export default ScreenTitle
