import { Container } from '@material-ui/core'
import { editSubcontractor } from 'actions/subcontractors'
import EditSubcontractorForm from 'components/Forms/EditSubcontractor'
import Navigation from 'components/Navigation'
import ScreenTitle from 'components/ScreenTitle'
import routes, { formatRoute } from 'config/routes'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

export const Edit = ({ subcontractor, editSubcontractor, history }) => {
  const [complete, setComplete] = useState()

  const submit = async (values) => {
    try {
      await editSubcontractor(
        subcontractor.id,
        subcontractor.organization,
        values
      )
      setComplete(true)
    } catch (err) {
      // re-raise for EditSubcontractorForm to handle
      throw err
    }
  }

  const cancel = () => {
    history.goBack()
  }

  if (complete) {
    const route = formatRoute(routes.subcontractor, { subId: subcontractor.id })
    return <Redirect to={route} />
  }

  return (
    <Navigation>
      <Container>
        <ScreenTitle>Edit Details</ScreenTitle>
        <EditSubcontractorForm
          onSubmit={submit}
          onCancel={cancel}
          subcontractor={subcontractor}
        />
      </Container>
    </Navigation>
  )
}

Edit.propTypes = {
  editSubcontractor: PropTypes.func.isRequired,
  subcontractor: PropTypes.object.isRequired,
}

const mapState = (state, props) => ({
  subcontractor: state.subcontractors.find(
    (s) => s.id === props.match.params.subId
  ),
})

const mapDispatch = {
  editSubcontractor,
}

export default connect(mapState, mapDispatch)(Edit)
