import { Container } from '@material-ui/core'
import { createPolicy } from 'actions/policies'
import EditPolicyForm from 'components/Forms/EditPolicy'
import Navigation from 'components/Navigation'
import ScreenTitle from 'components/ScreenTitle'
import routes, { formatRoute } from 'config/routes'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

export const Create = (props) => {
  const [complete, setComplete] = useState()

  const submit = async (values) => {
    try {
      await props.createPolicy(
        props.currentOrganization.id,
        props.subcontractor.id,
        values
      )
      setComplete(true)
    } catch (err) {
      // re-raise for AddSubcontractorForm to handle
      throw err
    }
  }

  const cancel = () => {
    props.history.goBack()
  }

  if (complete) {
    const route = formatRoute(routes.subcontractor, {
      subId: props.subcontractor.id,
    })
    return <Redirect to={route} />
  }

  return (
    <Navigation>
      <Container>
        <ScreenTitle>New Policy</ScreenTitle>
        <EditPolicyForm
          onSubmit={submit}
          onCancel={cancel}
          subcontractor={props.subcontractor}
        />
      </Container>
    </Navigation>
  )
}

Create.propTypes = {
  createPolicy: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  subcontractor: PropTypes.object.isRequired,
}

const mapState = (state) => ({
  currentOrganization: state.app.currentOrganization,
})

const mapDispatch = {
  createPolicy,
}

export default connect(mapState, mapDispatch)(Create)
