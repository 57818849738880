import api, { urls } from 'config/api'
import { getSubcontractor } from './subcontractors'

/**
 * Action types
 */
export const UPDATE_SUBCONTRACTOR_DOCUMENTS = 'UPDATE_SUBCONTRACTOR_DOCUMENTS'
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT'

/**
 * Action creators
 */
const _updateSubcontractorDocuments = (subId, documents) => ({
  type: UPDATE_SUBCONTRACTOR_DOCUMENTS,
  documents,
  subId,
})

const _removeDocument = (id) => ({
  type: REMOVE_DOCUMENT,
  id,
})

/**
 * Actions
 */
export const getSubcontractorDocuments = (orgId, subId) => async (dispatch) => {
  const resp = await api.get(urls.documents({ orgId }), {
    params: {
      subcontractor_id: subId,
    },
  })
  dispatch(_updateSubcontractorDocuments(subId, resp.data.results))
}

export const createDocument = (orgId, subId, values) => async (dispatch) => {
  const resp = await api.post(urls.documents({ orgId }), {
    ...values,
    subcontractor: subId,
  })
  // refresh all documents to account for removed W9 or agreement after
  // uploading a newer one
  await dispatch(getSubcontractorDocuments(orgId, subId))
  // needed to update `has_w9` and `has_agreement` fields after upload
  await dispatch(getSubcontractor(orgId, subId))
  return resp.data
}

export const getDocument = (orgId, id) => async (dispatch) => {
  const resp = await api.get(urls.document({ orgId, id }))
  return resp.data
}

export const deleteDocument = (orgId, subId, id) => async (dispatch) => {
  await api.delete(urls.document({ orgId, id }))
  dispatch(_removeDocument(id))
  // needed to update `has_w9` and `has_agreement` fields after upload
  await dispatch(getSubcontractor(orgId, subId))
}

export const getDocumentDownloadUrl = (orgId, id) => async (dispatch) => {
  const resp = await api.get(urls.documentDownload({ orgId, id }))
  return resp.data.url
}
