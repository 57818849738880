import { Container } from '@material-ui/core'
import { updateUserAttributes, updateUserEmailSettings } from 'actions/auth'
import ProfileForm from 'components/Forms/Profile'
import EmailSettingsForm from 'components/Forms/EmailSettings'
import Navigation from 'components/Navigation'
import ScreenTitle from 'components/ScreenTitle'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

function Profile(props) {
  return (
    <Navigation>
      <Container>
        <ScreenTitle>Profile</ScreenTitle>
        <ProfileForm
          email={props.email}
          initialValues={{
            given_name: props.given_name,
            family_name: props.family_name,
          }}
          onSubmit={props.updateUserAttributes}
        />
        <EmailSettingsForm
          initialValues={{
            red_flag_notifications: props.red_flag_notifications,
          }}
          onSubmit={props.updateUserEmailSettings}
        />
      </Container>
    </Navigation>
  )
}

Profile.propTypes = {
  email: PropTypes.string.isRequired,
  given_name: PropTypes.string,
  family_name: PropTypes.string,
  updateUserAttributes: PropTypes.func.isRequired,
  updateUserEmailSettings: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  email: state.auth.email,
  given_name: state.auth.given_name,
  family_name: state.auth.family_name,
  red_flag_notifications: state.auth.red_flag_notifications,
})

const mapDispatchToProps = {
  updateUserAttributes,
  updateUserEmailSettings,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
