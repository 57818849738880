import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import {
  getDocument,
  getSubcontractor,
  updateSubcontractor,
  uploadDocument,
  uploadPolicy,
} from 'actions/tokenUpload'
import EditPolicyForm from 'components/Forms/EditPolicy'
import EditSubcontractorForm from 'components/Forms/EditSubcontractor'
import LoadingContainer from 'components/LoadingContainer'
import PolicyUpload from 'components/PolicyUpload'
import TokenUploadFile from 'components/TokenUploadFile'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { renderLink } from 'utils'

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(6),
    textAlign: 'center',
    overflow: 'visible',
  },
  center: {
    margin: 'auto',
  },
  bodyText: {
    textAlign: 'left',
  },
  noPad: {
    padding: theme.spacing(0),
  },
  title: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2, 0),
    marginTop: theme.spacing(-5),
  },
  backButton: {
    marginRight: 'auto',
    marginBottom: theme.spacing(1),
  },
  formCard: {
    width: '100%',
    maxWidth: 600,
  },
}))

const ListItemLink = (props) => (
  <ListItem divider button component={renderLink(props.to)}>
    {props.complete ? (
      <ListItemIcon>
        <FontAwesomeIcon icon='check' className='fa-fw' />
      </ListItemIcon>
    ) : null}
    <ListItemText inset={!props.complete}>{props.text}</ListItemText>
    <ListItemIcon>
      <FontAwesomeIcon icon='angle-right' className='fa-fw fa-ul' />
    </ListItemIcon>
  </ListItem>
)

const hasCompanyDetails = (subcontractor) =>
  !!subcontractor.company_name &&
  !!subcontractor.company_phone &&
  !!subcontractor.company_address_street_1 &&
  !!subcontractor.company_address_city &&
  !!subcontractor.company_address_state &&
  !!subcontractor.company_address_zipcode &&
  !!subcontractor.primary_contact_email &&
  !!subcontractor.primary_contact_first_name &&
  !!subcontractor.primary_contact_last_name &&
  !!subcontractor.primary_contact_phone &&
  (subcontractor.residential || subcontractor.commercial) &&
  subcontractor.contract_types.length > 0

function Upload(props) {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(true)
  const [token, setToken] = React.useState()
  const [error, setError] = React.useState()
  const [data, setData] = React.useState({})
  const [policy, setPolicy] = React.useState({})

  React.useEffect(() => {
    const load = async () => {
      const params = new URLSearchParams(window.location.search)
      const _token = params.get('token')
      try {
        const _data = await props.getSubcontractor(_token)
        setToken(_token)
        setData(_data)
        setLoading(false)
      } catch (err) {
        console.error(err.response)
        setError(err.response.data.detail)
      }
    }

    load()
    // eslint-disable-next-line
  }, [])

  const loadDocument = async (id) => {
    return await props.getDocument(token, id)
  }

  const updateSubcontractor = async (values) => {
    const subcontractor = await props.updateSubcontractor(token, values)
    setData({ ...data, subcontractor })
    props.history.goBack()
  }

  const uploadDocument = (kind) => async (file) => {
    const document = await props.uploadDocument(token, {
      upload_id: file.serverId,
      filename: file.filename,
      kind,
    })
    // Additional processing for policies, other ones just go back
    if (kind !== 'policy') {
      const { subcontractor } = await props.getSubcontractor(token)
      setData({ ...data, subcontractor })
      props.history.goBack()
    }
    return document
  }

  const handleFinish = (policy) => {
    setPolicy(policy)
    props.history.push({
      pathname: routes.uploadPolicy,
      search: window.location.search,
    })
  }

  const uploadPolicy = async (values) => {
    await props.uploadPolicy(token, values)
    props.history.push({
      pathname: routes.upload,
      search: window.location.search,
    })
  }

  const organization = data.organization || ''
  const subcontractor = data.subcontractor || {}
  const navigation = React.useMemo(
    () => [
      {
        to: {
          pathname: routes.uploadReview,
          search: window.location.search,
        },
        text: 'Review Company Details',
        complete: hasCompanyDetails(subcontractor),
      },
      {
        to: {
          pathname: routes.uploadW9,
          search: window.location.search,
        },
        text: 'Upload W9',
        complete: subcontractor.has_w9,
      },
      {
        to: {
          pathname: routes.uploadAgreement,
          search: window.location.search,
        },
        text: 'Upload Agreement',
        complete: subcontractor.has_agreement,
      },
      {
        to: {
          pathname: routes.uploadPolicyDocument,
          search: window.location.search,
        },
        text: 'Upload a Certificate of Insurance',
        complete: false,
      },
    ],
    [subcontractor]
  )

  const BackButton = () => (
    <Grid item xs={12}>
      <Button
        onClick={props.history.goBack}
        startIcon={<FontAwesomeIcon icon='angle-left' />}
        className={classes.backButton}
      >
        Back
      </Button>
    </Grid>
  )

  return (
    <Container>
      <Grid
        container
        item
        justify='center'
        xs={12}
        md={6}
        spacing={2}
        className={classes.center}
      >
        {!!error ? (
          <Card className={classes.card}>
            <CardContent>
              <Typography variant='h4'>{error}</Typography>
            </CardContent>
          </Card>
        ) : (
          <Switch>
            <Route path={routes.uploadReview}>
              <BackButton />
              <EditSubcontractorForm
                onCancel={props.history.goBack}
                onSubmit={updateSubcontractor}
                subcontractor={subcontractor}
              />
            </Route>
            <Route path={routes.uploadW9}>
              <BackButton />
              <Card className={classes.formCard}>
                <CardContent>
                  <Typography variant='h6' gutterBottom>
                    Upload W9
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    Upload a copy of your W9.
                  </Typography>
                  <TokenUploadFile
                    token={token}
                    onUpload={uploadDocument('w9')}
                  />
                </CardContent>
              </Card>
            </Route>
            <Route path={routes.uploadAgreement}>
              <BackButton />
              <Card className={classes.formCard}>
                <CardContent>
                  <Typography variant='h6' gutterBottom>
                    Upload Agreement
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    Upload a copy of your subcontractor agreement.
                  </Typography>
                  <TokenUploadFile
                    token={token}
                    onUpload={uploadDocument('agreement')}
                  />
                </CardContent>
              </Card>
            </Route>
            <Route path={routes.uploadPolicyDocument}>
              <BackButton />
              <Card className={classes.formCard}>
                <CardContent>
                  <PolicyUpload
                    authHeader={`token ${token}`}
                    getDocument={loadDocument}
                    onFinish={handleFinish}
                    onUpload={uploadDocument('policy')}
                  />
                </CardContent>
              </Card>
            </Route>
            <Route path={routes.uploadPolicy}>
              <BackButton />
              <EditPolicyForm
                authHeader={`token ${token}`}
                onCancel={props.history.goBack}
                onSubmit={uploadPolicy}
                policy={policy}
                subcontractor={subcontractor}
              />
            </Route>
            <Route>
              <LoadingContainer loading={loading}>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography
                        variant='h4'
                        gutterBottom
                        className={classes.title}
                        component={Paper}
                      >
                        {subcontractor.company_name}
                      </Typography>
                      <Typography
                        variant='body1'
                        gutterBottom
                        className={classes.bodyText}
                      >
                        Thank you for taking time to Comply with the contract
                        requirements set forth in your agreement with{' '}
                        <strong>{organization}</strong>.
                      </Typography>
                      <Typography variant='body1' className={classes.bodyText}>
                        Please review your company details below and submit any
                        documentation you have available at this time.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.noPad}>
                    <List component='nav' className={classes.noPad}>
                      {navigation.map((linkProps, i) => (
                        <ListItemLink key={i} {...linkProps} />
                      ))}
                    </List>
                  </Paper>
                </Grid>
              </LoadingContainer>
            </Route>
          </Switch>
        )}
      </Grid>
    </Container>
  )
}

Upload.propTypes = {
  getDocument: PropTypes.func.isRequired,
  getSubcontractor: PropTypes.func.isRequired,
  updateSubcontractor: PropTypes.func.isRequired,
  uploadPolicy: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
}

const mapDispatch = {
  getDocument,
  getSubcontractor,
  updateSubcontractor,
  uploadPolicy,
  uploadDocument,
}

export default connect(null, mapDispatch)(Upload)
