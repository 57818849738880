// Standard format for dates
export const DATE_FORMAT = 'yyyy-MM-dd'
export const DATE_TIME_FORMAT = 'yyyy-MM-ddTHH:mm:ss.SSSSSS'

// Available Member Roles
export const ROLES = {
  owner: 'owner',
  admin: 'admin',
  member: 'member',
}
