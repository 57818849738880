import { Grid, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { useCommonStyles } from 'hooks'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedNumber } from 'react-intl'

function PlanDetails({ subscription }) {
  const commonClasses = useCommonStyles()

  const calculatePricing = () => {
    const { discount } = subscription
    let price = subscription.plan.amount
    if (
      !!discount &&
      (!discount.end_time ||
        discount.end_time > subscription.current_period_end)
    ) {
      return !!discount.coupon.percent_off
        ? price * (1 - discount.coupon.percent_off / 100.0)
        : price - discount.coupon.amount_off
    } else {
      return price
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid
        container
        direction='column'
        justify='space-between'
        alignItems='center'
        item
        xs={12}
        md={4}
      >
        <Grid item>
          <Typography variant='overline'>current plan</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h5' className={commonClasses.fontHeavy}>
            {subscription.plan.product.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction='column'
        justify='space-between'
        alignItems='center'
        item
        xs={12}
        md={4}
      >
        <Grid item>
          <Typography variant='overline'>pricing</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h5' className={commonClasses.fontHeavy}>
            <Typography variant='caption' className={commonClasses.vAlignSuper}>
              $
            </Typography>
            <FormattedNumber value={calculatePricing() / 100.0} />
            <Typography variant='caption'>
              /{subscription.plan.interval}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction='column'
        justify='space-between'
        alignItems='center'
        item
        xs={12}
        md={4}
      >
        <Grid item>
          <Typography variant='overline'>next billing date</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h5' className={commonClasses.fontHeavy}>
            {subscription.status === 'active' &&
            !subscription.cancel_at_period_end
              ? format(subscription.current_period_end * 1000, 'MMM d, yyyy')
              : '––'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

PlanDetails.propTypes = {
  subscription: PropTypes.object.isRequired,
}

export default PlanDetails
