import api, { urls } from 'config/api'
import * as auth from './auth'
import * as organizations from './organizations'
import * as policies from './policies'
import * as subcontractors from './subcontractors'
import * as subscription from './subscription'

/**
 * Action types
 */
export const RESET_APP = 'RESET_APP'
export const SET_LOADING = 'SET_LOADING'

/**
 * Action creators
 */
export const resetApp = () => ({ type: RESET_APP })
export const setLoading = loading => ({
  type: SET_LOADING,
  loading,
})

/**
 * Actions
 */
export const performStartup = () => async (dispatch, getState) => {
  dispatch(setLoading(true))
  await dispatch(organizations.getOrganizations())
  const organization = await dispatch(organizations.getCurrentOrganization())
  // Perform additional actions if we have an organization
  if (!!organization) {
    await Promise.all([
      dispatch(auth.getUserRole(organization.id)),
      dispatch(subscription.getSubscription(organization.id)),
      dispatch(subcontractors.getSubcontractors(organization.id)),
      dispatch(policies.getPolicies(organization.id)),
    ])
  }
  dispatch(setLoading(false))
}

export const logout = () => async dispatch => {
  dispatch(auth.logout())
  dispatch(resetApp())
}

export const getInviteDetails = code => async dispatch => {
  const resp = await api.get(urls.join(), {
    params: {
      code,
    },
  })
  return resp.data
}

export const acceptInvite = code => async dispatch => {
  const resp = await api.post(
    urls.join(),
    {},
    {
      params: {
        code,
      },
    }
  )
  // Immediately reload under the new organization
  await dispatch(organizations.addOrganization(resp.data))
  await dispatch(organizations.selectCurrentOrganization(resp.data.id))
  await dispatch(performStartup())
}
