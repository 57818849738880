import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Form as FormikForm, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    maxWidth: 600,
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}))

function EmailSettingsForm(props) {
  const { initialValues, onSubmit } = props
  const classes = useStyles()

  const handleSubmit = async (values, actions) => {
    try {
      await onSubmit(values)
    } catch (err) {
      console.error('Error updating email settings')
      console.error(err)
    }
    actions.setSubmitting(false)
  }

  return (
    <div className={classes.card}>
      <Typography variant='h6'>Email Notifications</Typography>
      <Card className={classes.card}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({
            values,
            dirty,
            isSubmitting,
            handleReset,
            handleChange,
            setFieldValue,
            setFieldTouched,
          }) => (
            <FormikForm>
              <CardContent>
                <FormControlLabel
                  label='Red Flag Notifications'
                  control={
                    <Checkbox
                      name='red_flag_notifications'
                      checked={values.red_flag_notifications}
                      onChange={(event) => {
                        setFieldValue(
                          'red_flag_notifications',
                          !values.red_flag_notifications
                        )
                        setFieldTouched('red_flag_notifications', true)
                      }}
                      value={values.red_flag_notifications}
                    />
                  }
                />
                <div>
                  <Typography variant='subtitle2'>
                    Receive a daily summary email whenever new red flags
                    are triggered for any subcontractors.
                  </Typography>
                </div>
              </CardContent>
              <CardActions>
                <Button
                  type='submit'
                  disabled={!dirty || isSubmitting}
                  variant={dirty ? 'contained' : null}
                  color={dirty ? 'primary' : 'secondary'}
                >
                  {isSubmitting ? 'Saving' : 'Save'}
                </Button>
                <Button disabled={!dirty || isSubmitting} onClick={handleReset}>
                  Cancel
                </Button>
              </CardActions>
            </FormikForm>
          )}
        </Formik>
      </Card>
    </div>
  )
}

EmailSettingsForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

EmailSettingsForm.defaultProps = {
  initialValues: {
    'red_flag_notifications': true,
  },
}

export default EmailSettingsForm
