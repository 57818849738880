import { Button, Grid, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { useCommonStyles } from 'hooks'
import PropTypes from 'prop-types'
import React from 'react'
import { toTitleCase } from 'utils'

// List of states we show an action button with
const actionStatuses = ['active', 'expiring']

const getSubscriptionStatus = (subscription) => {
  const { status, cancel_at_period_end } = subscription
  if (status === 'active' && cancel_at_period_end) {
    return 'expiring'
  } else {
    return status
  }
}

const getSubscriptionStatusText = (subscription) => {
  const status = getSubscriptionStatus(subscription)
  switch (status) {
    case 'expiring':
      return `Expires ${format(
        subscription.current_period_end * 1000,
        'MMM d, yyyy'
      )}`
    default:
      return toTitleCase(status)
  }
}

function Status(props) {
  const { subscription } = props
  const status = getSubscriptionStatus(subscription)
  const statusText = getSubscriptionStatusText(subscription)
  const commonClasses = useCommonStyles()

  const btnAction = () => {
    switch (status) {
      case 'active':
        return props.onCancel()
      case 'expiring':
        return props.onResume()
      default:
        console.warn('Unhandled btnAction:', status)
    }
  }

  return (
    <Grid container spacing={2} justify='center' alignItems='center'>
      <Grid item xs={12} md={6} className={commonClasses.textCenter}>
        <Typography variant='overline'>subscription status</Typography>
        <Typography variant='h6' className={commonClasses.fontHeavy}>
          {statusText}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} className={commonClasses.textCenter}>
        {actionStatuses.includes(status) ? (
          <Button
            color='primary'
            variant='contained'
            disabled={props.updating}
            onClick={btnAction}
          >
            {status === 'expiring'
              ? props.updating
                ? 'Resuming'
                : 'Resume Subscription'
              : props.updating
              ? 'Cancelling'
              : 'Cancel Subscription'}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  )
}

Status.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onResume: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
  updating: PropTypes.bool.isRequired,
}

export default Status
