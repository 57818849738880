import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcStripe,
  faCcVisa
} from '@fortawesome/free-brands-svg-icons'
import {
  faAddressCard,
  faArchive,
  faCreditCard,
  faEnvelope,
  faPaperPlane,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBars,
  faBuilding,
  faCheck,
  faCloudDownload,
  faFax,
  faFlag,
  faHome,
  faPencil,
  faPhone,
  faPlus,
  faSignOut,
  faTrashAlt,
  faUser,
  faUsers
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faAddressCard,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faBars,
  faBuilding,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcStripe,
  faCcVisa,
  faCreditCard,
  faCheck,
  faCloudDownload,
  faEnvelope,
  faFax,
  faFlag,
  faHome,
  faPaperPlane,
  faPencil,
  faPhone,
  faPlus,
  faSignOut,
  faTimes,
  faTrashAlt,
  faUser,
  faUsers
)
