import { makeStyles } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import { getPermissions } from 'config/permissions'
import React, { useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

/**
 * Get the JWT from Amplify
 */
export const useAuthHeader = (header) => {
  const [authHeader, setAuthHeader] = useState(header)

  useEffect(() => {
    const getAuth = async () => {
      try {
        const session = await Auth.currentSession()
        setAuthHeader(`bearer ${session.idToken.jwtToken}`)
      } catch (err) {
        console.error(err)
      }
    }

    if (!authHeader) {
      getAuth()
    }
  })

  return authHeader
}

/**
 * Invoke a function on location change
 */
export const useLocationEffect = (path, effect) => {
  const { pathname } = useLocation()

  useEffect(() => {
    const match = matchPath(pathname, { path, exact: true })
    if (match) {
      effect(match)
    }

    // eslint-disable-next-line
  }, [pathname])
}

/**
 * Permissions context
 */
export const PermissionsContext = React.createContext(getPermissions())

/**
 * Common styles
 */
export const useCommonStyles = makeStyles((theme) => ({
  mt1: { marginTop: theme.spacing(1) },
  mr1: { marginRight: theme.spacing(1) },
  ml1: { marginLeft: theme.spacing(1) },
  mb1: { marginBottom: theme.spacing(1) },
  mt2: { marginTop: theme.spacing(2) },
  mr2: { marginRight: theme.spacing(2) },
  ml2: { marginLeft: theme.spacing(2) },
  mb2: { marginBottom: theme.spacing(2) },
  mw600: { maxWidth: 600 },
  error: { color: theme.palette.error.main },
  textCenter: { textAlign: 'center' },
  textLeft: { textAlign: 'left' },
  textRight: { textAlign: 'right' },
  fontHeavy: { fontWeight: 700 },
  vAlignSuper: { verticalAlign: 'super' },
  hr: { margin: theme.spacing(4, 0), opacity: 0.3 },
}))
