import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Fab, makeStyles, Typography } from '@material-ui/core'
import DefaultTable from 'components/Tables/Default'
import routes, { formatRoute } from 'config/routes'
import { PermissionsContext } from 'hooks'
import PropTypes from 'prop-types'
import React from 'react'
import { renderLink } from 'utils'
import CoveragesTable from './Coverages'

const useStyles = makeStyles((theme) => ({
  textCenter: {
    textAlign: 'center',
  },
  bodyNoOrgs: {
    padding: theme.spacing(3, 0),
  },
  white: {
    color: 'white',
  },
  btnMarginRight: {
    marginRight: theme.spacing(1),
  },
  addBtn: {
    float: 'right',
  },
  iconRed: {
    color: theme.palette.error.main,
  },
}))

const FlaggedCell = (classes) => ({ cell: { value }, row }) =>
  value.filter(f => !f.dismissed).length > 0 && (
    <FontAwesomeIcon icon='flag' className={`fa-fw fa-lg ${classes.iconRed}`} />
  )

const ActionsCell = (onDownload, onDelete, orgId, classes, permissions) => ({
  cell: { value },
  row,
}) => {
  const editRoute = formatRoute(routes.policyEdit, {
    subId: row.original.subcontractor,
    policyId: row.original.id,
  })

  const download = (event) => {
    event.preventDefault()
    onDownload(orgId, row.original.id)
  }

  const delete_ = async () => {
    const msg =
      'Are you sure you want to delete this policy? You cannot undo this action.'
    if (window.confirm(msg)) {
      try {
        await onDelete(orgId, row.original.id)
      } catch (err) {
        console.error('Unable to delete policy')
        console.error(err)
      }
    }
  }

  return (
    <div>
      <Fab
        size='small'
        color='primary'
        aria-label='download'
        onClick={download}
        className={classes.btnMarginRight}
      >
        <FontAwesomeIcon icon='cloud-download' className='fa-fw' />
      </Fab>
      <Fab
        size='small'
        color='primary'
        aria-label='edit'
        component={renderLink(editRoute)}
        className={classes.btnMarginRight}
      >
        <FontAwesomeIcon icon='pencil' className='fa-fw' />
      </Fab>
      {permissions.canDeletePolicies ? (
        <Fab
          size='small'
          color='secondary'
          aria-label='delete'
          onClick={delete_}
        >
          <FontAwesomeIcon
            icon='trash-alt'
            className={`fa-fw ${classes.white}`}
          />
        </Fab>
      ) : null}
    </div>
  )
}

function PoliciesTable({ onDelete, onDownload, policies, subcontractor }) {
  const classes = useStyles()
  const data = React.useMemo(() => policies, [policies])
  const permissions = React.useContext(PermissionsContext)
  const columns = React.useMemo(
    () => [
      {
        id: 'flagged',
        Cell: FlaggedCell(classes),
        accessor: 'flags',
      },
      {
        Header: 'POLICY DETAILS',
        Cell: CoveragesTable,
        accessor: 'coverages',
      },
      {
        Header: 'ACTIONS',
        Cell: ActionsCell(
          onDownload,
          onDelete,
          subcontractor.organization,
          classes,
          permissions
        ),
      },
    ],
    [onDelete, onDownload, classes, subcontractor, permissions]
  )
  return (
    <div>
      {policies.length > 0 ? (
        <Button
          color='primary'
          variant='contained'
          startIcon={<FontAwesomeIcon icon='plus' />}
          className={classes.addBtn}
          component={renderLink(
            formatRoute(routes.policyUpload, { subId: subcontractor.id })
          )}
        >
          Add Policy
        </Button>
      ) : null}
      {policies.length === 0 ? (
        <div className={classes.textCenter}>
          <Typography vairant='body1' className={classes.bodyNoOrgs}>
            There aren't any policies
          </Typography>
          <Button
            variant='contained'
            color='primary'
            component={renderLink(
              formatRoute(routes.policyUpload, { subId: subcontractor.id })
            )}
            startIcon={<FontAwesomeIcon icon='plus' className={`fa-fw`} />}
          >
            Add Policy
          </Button>
        </div>
      ) : (
        <DefaultTable columns={columns} data={data} headers={false} />
      )}
    </div>
  )
}

PoliciesTable.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  policies: PropTypes.array.isRequired,
  subcontractor: PropTypes.object.isRequired,
}

export default PoliciesTable
