import api, { urls } from 'config/api'

const LS_ORG_ID = 'cc-oid'

/**
 * Action types
 */
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS'
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION'
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION'

/**
 * Action creators
 */
const _setOrganizations = organizations => ({
  type: SET_ORGANIZATIONS,
  organizations,
})

const _setCurrentOrganization = organization => ({
  type: SET_CURRENT_ORGANIZATION,
  organization,
})

const _addOrganization = organization => ({
  type: ADD_ORGANIZATION,
  organization,
})

/**
 * Actions
 */
export const addOrganization = organization => async dispatch => {
  dispatch(_addOrganization(organization))
}

export const getOrganizations = () => async dispatch => {
  const resp = await api.get(urls.organizations())
  dispatch(_setOrganizations(resp.data.results))
}

export const createOrganization = ({ name }) => async (dispatch, getState) => {
  const resp = await api.post(urls.organizations(), {
    name,
  })
  dispatch(_addOrganization(resp.data))
  dispatch(selectCurrentOrganization(resp.data.id))
}

export const getCurrentOrganization = () => async (dispatch, getState) => {
  const { organizations } = getState()
  const lastOrgId = localStorage.getItem(LS_ORG_ID)
  const organization =
    organizations.find(o => o.id === lastOrgId) || organizations[0]
  await dispatch(_setCurrentOrganization(organization))
  return organization
}

export const selectCurrentOrganization = id => async (dispatch, getState) => {
  const organization = getState().organizations.find(o => o.id === id)
  dispatch(_setCurrentOrganization(organization))
  localStorage.setItem(LS_ORG_ID, organization.id)
}

export const batchSend = (email, subcontractors) => async (dispatch, getState) => {
  const orgId = getState().app.currentOrganization.id
  await api.post(urls.batchSend({orgId}), {
    email,
    subcontractors,
  })
}
