import { ROLES } from './constants'

const Permissions = {
  canManageBilling: [ROLES.admin, ROLES.owner],
  canManageTeam: [ROLES.admin, ROLES.owner],
  canDeleteSubcontractors: [ROLES.admin, ROLES.owner],
  canDeleteDocuments: [ROLES.admin, ROLES.owner],
  canDeletePolicies: [ROLES.admin, ROLES.owner],
}

export const getPermissions = (role) =>
  Object.keys(Permissions).reduce((perms, key) => {
    perms[key] = Permissions[key].includes(role)
    return perms
  }, {})
