import LoadingWave from '@bit/ngoue.playground.loading-wave'
import { makeStyles, useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  centered: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

function LoadingContainer({ loading, children }) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <>
      {loading ? (
        <div className={classes.centered}>
          <LoadingWave
            primaryColor={theme.palette.primary.main}
            secondaryColor={theme.palette.secondary.main}
          />
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

LoadingContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
}

export default LoadingContainer
