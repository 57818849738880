import api, { urls } from 'config/api'

/**
 * Action types
 */
export const SET_INVITES = 'SET_INVITES'
export const ADD_INVITE = 'ADD_INVITE'
export const DELETE_INVITE = 'DELETE_INVITE'

/**
 * Action creators
 */
const _setInvites = invites => ({
  type: SET_INVITES,
  invites,
})

const _addInvite = invite => ({
  type: ADD_INVITE,
  invite,
})

const _deleteInvite = id => ({
  type: DELETE_INVITE,
  id,
})

/**
 * Actions
 */
export const getInvites = orgId => async dispatch => {
  const resp = await api.get(urls.invites({ orgId }))
  dispatch(_setInvites(resp.data.results))
}

export const addInvite = (orgId, values) => async dispatch => {
  const resp = await api.post(urls.invites({ orgId }), {
    ...values,
  })
  dispatch(_addInvite(resp.data))
}

export const deleteInvite = (orgId, id) => async dispatch => {
  await api.delete(urls.invite({ orgId, id }))
  dispatch(_deleteInvite(id))
}
