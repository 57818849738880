import { useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import LoadingWave from '@bit/ngoue.playground.loading-wave'

function Wrapper(props) {
  const theme = useTheme()
  const primaryColor = props.primaryColor || theme.palette.primary.main
  const secondaryColor = props.secondaryColor || theme.palette.secondary.main
  return (
    <div className={props.className}>
      <LoadingWave
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      />
    </div>
  )
}

Wrapper.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
}

export default Wrapper
