import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Fab, makeStyles } from '@material-ui/core'
import { getSubcontractors } from 'actions/subcontractors'
import Navigation from 'components/Navigation'
import ScreenTitle from 'components/ScreenTitle'
import BatchSendDialog from 'components/Subcontractors/BatchSendDialog'
import SubcontractorsTable from 'components/Tables/Subcontractors'
import { batchSend } from 'actions/organizations'
import routes from 'config/routes'
import { useLocationEffect } from 'hooks'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { renderLink } from 'utils'
import Create from './Create'
import Details from './Details'
import Edit from './Edit'

const useStyles = makeStyles((theme) => ({
  actions: {
    margin: theme.spacing(2, 1),
  },
  btnMargin: {
    marginRight: theme.spacing(1),
  },
  white: {
    color: 'white',
  },
}))

function Subcontractors({
  subcontractors,
  history,
  batchSend,
  getSubcontractors,
  currentOrganization,
}) {
  const classes = useStyles()
  const [selected, setSelected] = React.useState([])
  const [showBatchSendModal, setShowBatchSendModal] = React.useState(false)

  useLocationEffect(routes.subcontractors, async () => {
    await getSubcontractors(currentOrganization.id)
  })

  const openBatchSendModal = () => {
    setShowBatchSendModal(true)
  }

  const closeBatchSendModal = () => {
    setShowBatchSendModal(false)
  }

  const handleBatchSend = async (email) => {
    let sent = false
    try {
      await batchSend(email, selected)
      sent = true
      closeBatchSendModal()
      setSelected([])
    } catch (err) {
      console.error('error in batch send')
      console.error(err)
    }
    return sent
  }

  return (
    <Switch>
      <Route exact path={routes.subcontractors}>
        <Navigation>
          <Container>
            <ScreenTitle>Subcontractors</ScreenTitle>
            {subcontractors.length > 0 ? (
              <div className={classes.actions}>
                <Fab
                  color='primary'
                  component={renderLink(routes.subcontractorNew)}
                  className={classes.btnMargin}
                >
                  <FontAwesomeIcon icon='plus' className='fa-lg' />
                </Fab>
                <Fab
                  variant='extended'
                  color='secondary'
                  className={classes.white}
                  disabled={selected.length === 0}
                  onClick={openBatchSendModal}
                >
                  <FontAwesomeIcon
                    icon='paper-plane'
                    className={`fa-lg fa-fw ${classes.btnMargin}`}
                  />
                  Batch Send
                </Fab>
              </div>
            ) : null}
            <BatchSendDialog
              open={showBatchSendModal}
              onClose={closeBatchSendModal}
              onSend={handleBatchSend}
              subcontractors={subcontractors.filter((s) =>
                selected.includes(s.id)
              )}
            />
            <SubcontractorsTable
              subcontractors={subcontractors}
              selected={selected}
              setSelected={setSelected}
            />
          </Container>
        </Navigation>
      </Route>
      <Route exact path={routes.subcontractorNew} component={Create} />
      <Route exact path={routes.subcontractorEdit} component={Edit} />
      <Route path={routes.subcontractor} component={Details} />
    </Switch>
  )
}

Subcontractors.propTypes = {
  batchSend: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  getSubcontractors: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  subcontractors: PropTypes.array.isRequired,
}

const mapState = (state) => ({
  currentOrganization: state.app.currentOrganization,
  subcontractors: state.subcontractors,
})

const mapDispatch = {
  batchSend,
  getSubcontractors,
}

export default connect(mapState, mapDispatch)(Subcontractors)
