import { performStartup } from 'actions'
import { login } from 'actions/auth'
import { withAuthenticator } from 'aws-amplify-react'
import LoadingContainer from 'components/LoadingContainer'
import { authConfig } from 'config/amplify'
import { getPermissions } from 'config/permissions'
import routes from 'config/routes'
import { PermissionsContext } from 'hooks'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import Dashboard from 'screens/Dashboard'
import Join from 'screens/Join'
import CreateOrganization from 'screens/Organizations/Create'
import Profile from 'screens/Profile'
import Setup from 'screens/Setup'
import Subcontractors from 'screens/Subcontractors'
import Subscription from 'screens/Subscription'
import Team from 'screens/Team'

function Main(props) {
  React.useEffect(() => {
    const load = async () => {
      await props.login()
      await props.performStartup()
    }
    load()
    // eslint-disable-next-line
  }, [])

  const permissions = React.useMemo(() => getPermissions(props.role), [
    props.role,
  ])

  const needsSetup =
    props.organizations.length === 0 && props.location.pathname !== routes.setup

  const needsSubscription =
    !needsSetup &&
    (props.subscription === null || props.subscription.status !== 'active') &&
    !props.location.pathname.startsWith(routes.subscription)

  return (
    <PermissionsContext.Provider value={permissions}>
      <LoadingContainer loading={props.loading}>
        <Switch>
          <Route exact path={routes.join} component={Join} />
          <Route path={routes.setup} component={Setup} />
          {needsSetup ? <Redirect to={routes.setup} /> : null}
          <Route path={routes.subscription} component={Subscription} />
          {needsSubscription ? <Redirect to={routes.subscription} /> : null}
          <Route exact path={routes.dashboard} component={Dashboard} />
          <Route path={routes.team} component={Team} />
          <Route path={routes.profile} component={Profile} />
          <Route
            path={routes.organizationsNew}
            component={CreateOrganization}
          />
          <Route path={routes.subcontractors} component={Subcontractors} />
          <Redirect to={routes.dashboard} />
        </Switch>
      </LoadingContainer>
    </PermissionsContext.Provider>
  )
}

Main.propTypes = {
  performStartup: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  role: PropTypes.string,
}

const mapState = (state) => ({
  loading: state.app.loading,
  organizations: state.organizations,
  role: state.auth.role,
  subscription: state.subscription,
})

const mapDispatch = {
  login,
  performStartup,
}

const ConnectedMain = connect(mapState, mapDispatch)(Main)

export default withAuthenticator(ConnectedMain, authConfig)
