import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Card,
  CardContent,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import { getData, getPolicyDownloadUrl } from 'actions/review'
import LoadingContainer from 'components/LoadingContainer'
import ReviewPoliciesTable from 'components/Tables/ReviewPolicies'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(6),
    textAlign: 'center',
    overflow: 'visible',
  },
  center: {
    margin: 'auto',
  },
  bodyText: {
    textAlign: 'left',
  },
  noPad: {
    padding: theme.spacing(0),
  },
  title: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2, 0),
    marginTop: theme.spacing(-5),
  },
  backButton: {
    marginRight: 'auto',
    marginBottom: theme.spacing(1),
  },
  formCard: {
    width: '100%',
    maxWidth: 600,
  },
  flexColumn: {
    flexDirection: 'column',
  },
}))

function Review({ getData, getPolicyDownloadUrl }) {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(true)
  const [token, setToken] = React.useState()
  const [error, setError] = React.useState()
  const [expanded, setExpanded] = React.useState(false)
  const [data, setData] = React.useState({})
  const { organization, subcontractors, policies } = data

  React.useEffect(() => {
    const load = async () => {
      const params = new URLSearchParams(window.location.search)
      const _token = params.get('token')
      try {
        const _data = await getData(_token)
        setToken(_token)
        setData({
          ..._data,
          subcontractors: [..._.sortBy(_data.subcontractors, ['company_name'])],
        })
        setLoading(false)
      } catch (err) {
        console.error(err.response)
        setError(err.response.data.detail)
      }
    }

    load()
  }, [setError, getData])

  const handleExpanded = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false)
  }

  const handlePolicyDownload = async (id) => {
    try {
      const url = await getPolicyDownloadUrl(token, id)
      window.open(url, '_blank')
    } catch (err) {
      console.error('unable to download policy')
      console.error(err)
    }
  }

  return (
    <Container>
      <Grid
        container
        item
        justify='center'
        xs={12}
        md={6}
        spacing={2}
        className={classes.center}
      >
        {!!error ? (
          <Card className={classes.card}>
            <CardContent>
              <Typography variant='h4'>{error}</Typography>
            </CardContent>
          </Card>
        ) : (
          <LoadingContainer loading={loading}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography
                    variant='h4'
                    gutterBottom
                    className={classes.title}
                    component={Paper}
                  >
                    Review
                  </Typography>
                  <Typography variant='body1' className={classes.bodyText}>
                    <strong>{organization?.name}</strong> has granted you
                    temporary access to review policies for the subcontractors
                    listed below.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              {subcontractors &&
                subcontractors.map((subcontractor) => (
                  <ExpansionPanel
                    key={subcontractor.id}
                    expanded={expanded === subcontractor.id}
                    onChange={handleExpanded(subcontractor.id)}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<FontAwesomeIcon icon='angle-down' />}
                    >
                      <Typography variant='h6'>
                        {subcontractor.company_name}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.flexColumn}>
                      <ReviewPoliciesTable
                        onDownload={handlePolicyDownload}
                        policies={policies.filter(
                          (policy) => policy.subcontractor === subcontractor.id
                        )}
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
            </Grid>
          </LoadingContainer>
        )}
      </Grid>
    </Container>
  )
}

Review.propTypes = {
  getData: PropTypes.func.isRequired,
  getPolicyDownloadUrl: PropTypes.func.isRequired,
}

const mapDispatch = {
  getData,
  getPolicyDownloadUrl,
}

export default connect(null, mapDispatch)(Review)
