import DateFnsUtils from '@date-io/date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { createDocument, deleteDocument, getDocumentDownloadUrl } from 'actions/documents'
import { removeAdditionalInsuredDocument } from 'actions/policies'
import { API_URL, urls } from 'config/api'
import { DATE_FORMAT } from 'config/constants'
import { add, format, parse } from 'date-fns'
import { FieldArray, Form as FormikForm, Formik } from 'formik'
import { useAuthHeader } from 'hooks'
import PropTypes from 'prop-types'
import React from 'react'
import { FilePond } from 'react-filepond'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toTitleCase } from 'utils'
import * as Yup from 'yup'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
  },
  coverage: {
    margin: theme.spacing(3, 2),
    padding: theme.spacing(2),
    boxShadow: '0px 0px 6px 2px rgba(0,0,0,0.15)',
  },
  header: {
    marginTop: theme.spacing(3),
  },
  error: {
    color: theme.palette.error.main,
  },
  divider: {
    maxWidth: '70%',
    borderColor: theme.palette.divider,
  },
  upload: {
    margin: theme.spacing(2, 0),
  },
  chipContainer: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}))

const CATEGORIES = [
  'general_liability',
  'automobile_liability',
  'umbrella_liability',
  'workers_compensation_liability',
]

const AI_CATEGORIES = [
  'additional_insured_ongoing_operations',
  'additional_insured_completed_operations',
  'primary_wording',
  'waiver_of_subrogation',
]

const create_coverage = (values = {}) => ({
  carrier: '',
  kind: '',
  amount: '',
  effective_date: new Date(),
  expiration_date: add(new Date(), { years: 1 }),
  additional_insured: false,
  waiver_of_subrogation: false,
  policy_number: '',
  ...values,
})

function EditPolicyForm(props) {
  const { onCancel, onSubmit, policy, subcontractor } = props
  const classes = useStyles()
  const location = useLocation()
  const authHeader = useAuthHeader(props.authHeader)
  const {
    coverages = [create_coverage()],
    additional_insured_categories = [],
    ...rest
  } = policy || location?.state?.policy || {}
  let initial = Object.assign(
    {
      document: null,
      terms: null,
      additional_insured_documents: [],
      additional_insured_uploads: [],
      additional_insured_categories: [...additional_insured_categories],
      coverages: coverages.map(
        ({ effective_date, expiration_date, ...coverage }) => ({
          ...coverage,
          effective_date:
            typeof effective_date === 'string'
              ? parse(effective_date, DATE_FORMAT, new Date())
              : effective_date,
          expiration_date:
            typeof expiration_date === 'string'
              ? parse(expiration_date, DATE_FORMAT, new Date())
              : expiration_date,
        })
      ),
    },
    rest
  )

  const validationSchema = Yup.object().shape({
    document: Yup.string().required(
      'Please go back and try uploading your document again.'
    ),
    coverages: Yup.array()
      .required('Must include coverage')
      .min(1, 'Must list at least one type of coverage'),
    additional_insured_categories: Yup.array()
      .required('Must include policy language categories')
      .min(1, 'Must indicate at least one policy language category'),
  })

  const validate = (values) => {
    const errors = {}
    if (
      (!policy || policy.additional_insured_documents.length === 0) &&
      values.additional_insured_uploads.length === 0
    ) {
      errors.additional_insured_uploads =
        'You must upload a document outlining your policy language'
    }
    return errors
  }

  const submit = async (values, actions) => {
    const { coverages, ...rest } = values
    const processed = {
      ...rest,
      coverages: coverages.map(
        ({ effective_date, expiration_date, ...coverage }) => ({
          ...coverage,
          effective_date: format(effective_date, DATE_FORMAT),
          expiration_date: format(expiration_date, DATE_FORMAT),
        })
      ),
    }
    try {
      await onSubmit(processed)
    } catch (err) {
      console.error('Error updating policy')
      console.error(err)
      actions.setSubmitting(false)
    }
  }

  const handleTermsUpload = async (file, setFieldValue) => {
    try {
      const document = await props.createDocument(
        subcontractor.organization,
        subcontractor.id,
        {
          kind: 'terms',
          filename: file.filename,
          upload_id: file.serverId,
        }
      )
      setFieldValue('terms', document.id)
    } catch (err) {
      console.error('Error uploading policy terms')
      console.error(err)
    }
  }

  const handleDownloadAdditionalInsuredDocument = async (document) => {
    const url = await props.getDocumentDownloadUrl(
      subcontractor.organization,
      document.id
    )
    window.open(url, '_blank')
  }

  const handleDeleteAdditionalInsuredDocument = async (document) => {
    const msg = `Are you sure you want to remove ${document.filename}?`
    if (window.confirm(msg)) {
      try {
        await props.deleteDocument(
          subcontractor.organization,
          subcontractor.id,
          document.id
        )
        await props.removeAdditionalInsuredDocument(policy.id, document.id)
      } catch (err) {
        console.error('Error deleting additional insured document')
        console.error(err)
      }
    }
  }

  return (
    <Card className={classes.card}>
      <Formik
        initialValues={initial}
        isInitialValid={true}
        onReset={onCancel}
        onSubmit={submit}
        validate={validate}
        validationSchema={validationSchema}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleReset,
          isSubmitting,
          isValid,
          setFieldTouched,
          setFieldValue,
          touched,
          values,
        }) => (
          <FormikForm>
            <CardContent>
              {!!values.document ? (
                <>
                  {!policy?.terms && (
                    <>
                      <Typography variant='h6'>Policy Terms</Typography>
                      <Typography>
                        Optionally upload a copy of the policy terms.
                      </Typography>
                      <FilePond
                        id='upload'
                        name='upload'
                        className={classes.upload}
                        maxFileSize='25MB'
                        acceptedFileTypes={['application/pdf']}
                        server={{
                          url: API_URL,
                          process: urls.upload(),
                          revert: urls.upload(),
                          fetch: null,
                          headers: {
                            Authorization: authHeader,
                          },
                        }}
                        onprocessfile={(error, file) => {
                          if (!error) {
                            handleTermsUpload(file, setFieldValue)
                          }
                        }}
                      />
                    </>
                  )}
                  <Typography variant='h6'>Policy Language</Typography>
                  <Typography>
                    Upload a copy of your Additional Insured, Primary Wording
                    and Waiver of Subrogation Documents.
                  </Typography>
                  <FilePond
                    id='ai_upload'
                    name='upload'
                    allowMultiple={true}
                    className={classes.upload}
                    maxFileSize='25MB'
                    acceptedFileTypes={['application/pdf']}
                    server={{
                      url: API_URL,
                      process: urls.upload(),
                      revert: urls.upload(),
                      fetch: null,
                      headers: {
                        Authorization: authHeader,
                      },
                    }}
                    onprocessfile={(error, file) => {
                      if (!error) {
                        setFieldValue('additional_insured_uploads', [
                          ...values.additional_insured_uploads,
                          file.serverId,
                        ])
                      }
                    }}
                  />
                  {errors.additional_insured_uploads &&
                    touched.additional_insured_uploads && (
                      <Typography variant='subtitle2' className={classes.error}>
                        {errors.additional_insured_uploads}
                      </Typography>
                    )}
                  <div className={classes.chipContainer}>
                    {(policy?.additional_insured_documents || []).map((ai) => (
                      <Chip
                        key={ai.id}
                        color='primary'
                        label={ai.filename}
                        onClick={() =>
                          handleDownloadAdditionalInsuredDocument(ai)
                        }
                        onDelete={() =>
                          handleDeleteAdditionalInsuredDocument(ai)
                        }
                      />
                    ))}
                  </div>
                  <Typography className={classes.upload}>
                    Please check the boxes below to indicate which documents you
                    uploaded (one document may cover all categories).
                  </Typography>
                  <div className={`aiCategoriesContainer ${classes.upload}`}>
                    <Grid container spacing={2}>
                      {AI_CATEGORIES.map((category) => (
                        <Grid key={category} item xs={12} md={6}>
                          <FormControlLabel
                            label={toTitleCase(category)}
                            control={
                              <Checkbox
                                name={category}
                                checked={values.additional_insured_categories.includes(
                                  category
                                )}
                                onChange={(event) => {
                                  const checked = values.additional_insured_categories.includes(
                                    category
                                  )
                                  setFieldValue(
                                    'additional_insured_categories',
                                    checked
                                      ? values.additional_insured_categories.filter(
                                          (c) => c !== category
                                        )
                                      : [
                                          ...values.additional_insured_categories,
                                          category,
                                        ]
                                  )
                                  setFieldTouched(
                                    'additional_insured_categories',
                                    true
                                  )
                                }}
                                value={category}
                              />
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                    {errors.additional_insured_categories &&
                      touched.additional_insured_categories && (
                        <Typography
                          variant='subtitle2'
                          className={classes.error}
                        >
                          {errors.additional_insured_categories}
                        </Typography>
                      )}
                  </div>
                  <Typography variant='h6'>Coverage</Typography>
                  <Typography>
                    Please include information about each type of coverage
                    included with this policy.
                  </Typography>
                  <FieldArray
                    name='coverages'
                    render={(arrayHelpers) => (
                      <div>
                        {values.coverages.map((c, index) => (
                          <Paper key={index} className={classes.coverage}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  required
                                  label='Carrier'
                                  id={`coverages-${index}-carrier`}
                                  name={`coverages.${index}.carrier`}
                                  value={values.coverages[index].carrier}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  // error={!!touched.carrier && !!errors.carrier}
                                  // helperText={
                                  //   touched.carrier &&
                                  //   !!errors.carrier &&
                                  //   errors.carrier
                                  // }
                                />
                              </Grid>
                              <Grid container item spacing={2}>
                                <Grid item md={6}>
                                  <Autocomplete
                                    id={`coverages-${index}-kind`}
                                    name={`coverages.${index}.kind`}
                                    value={values.coverages[index].kind}
                                    autoSelect
                                    freeSolo
                                    clearOnEscape
                                    options={CATEGORIES}
                                    getOptionLabel={(option) =>
                                      toTitleCase(option)
                                    }
                                    onChange={(event, value) => {
                                      setFieldValue(
                                        `coverages.${index}.kind`,
                                        !!value
                                          ? value
                                              .replace(/\s/g, '_')
                                              .toLowerCase()
                                          : value
                                      )
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        required
                                        label='Type'
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item md={6}>
                                  <TextField
                                    className={classes.input}
                                    fullWidth
                                    required
                                    type='number'
                                    label='Amount'
                                    id={`coverages-${index}-amount`}
                                    name={`coverages.${index}.amount`}
                                    value={values.coverages[index].amount}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    // error={!!touched.carrier && !!errors.carrier}
                                    // helperText={
                                    //   touched.carrier &&
                                    //   !!errors.carrier &&
                                    //   errors.carrier
                                    // }
                                  />
                                </Grid>
                              </Grid>
                              <Grid container item spacing={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <Grid item md={6}>
                                    <KeyboardDatePicker
                                      className={classes.input}
                                      fullWidth
                                      disableToolbar
                                      required
                                      variant='inline'
                                      format='MM/dd/yyyy'
                                      margin='normal'
                                      id={`coverages-${index}-effective-date`}
                                      name={`coverages.${index}.effective_date`}
                                      label='Effective Date'
                                      value={
                                        values.coverages[index].effective_date
                                      }
                                      onChange={(date) => {
                                        setFieldValue(
                                          `coverages.${index}.effective_date`,
                                          date
                                        )
                                        setFieldValue(
                                          `coverages.${index}.expiration_date`,
                                          add(date, { years: 1 })
                                        )
                                      }}
                                      onBlur={() =>
                                        setFieldTouched(
                                          `coverages.${index}.effective_date`,
                                          true,
                                          true
                                        )
                                      }
                                      // error={touched.effective_date && !!errors.effective_date}
                                      // helperText={
                                      //   touched.effective_date &&
                                      //   !!errors.effective_date &&
                                      //   errors.effective_date
                                      // }
                                      KeyboardButtonProps={{
                                        'aria-label': 'change effective date',
                                      }}
                                    />
                                  </Grid>
                                  <Grid item md={6}>
                                    <KeyboardDatePicker
                                      className={classes.input}
                                      fullWidth
                                      disableToolbar
                                      required
                                      variant='inline'
                                      format='MM/dd/yyyy'
                                      margin='normal'
                                      id={`coverages-${index}-expiration-date`}
                                      name={`coverages.${index}.expiration_date`}
                                      label='Expiration Date'
                                      value={
                                        values.coverages[index].expiration_date
                                      }
                                      onChange={(date) =>
                                        setFieldValue(
                                          `coverages.${index}.expiration_date`,
                                          date
                                        )
                                      }
                                      onBlur={() =>
                                        setFieldTouched(
                                          `coverages.${index}.expiration_date`,
                                          true,
                                          true
                                        )
                                      }
                                      // error={
                                      //   touched.expiration_date &&
                                      //   !!errors.expiration_date
                                      // }
                                      // helperText={
                                      //   touched.expiration_date &&
                                      //   !!errors.expiration_date &&
                                      //   errors.expiration_date
                                      // }
                                      KeyboardButtonProps={{
                                        'aria-label': 'change expiration date',
                                      }}
                                    />
                                  </Grid>
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid container item xs={12} justify='flex-end'>
                                <Button
                                  size='small'
                                  variant='contained'
                                  color='primary'
                                  onClick={() => arrayHelpers.remove(index)}
                                  disabled={values.coverages.length <= 1}
                                  startIcon={<FontAwesomeIcon icon='times' />}
                                >
                                  Remove
                                </Button>
                              </Grid>
                            </Grid>
                          </Paper>
                        ))}
                        <Button
                          color='primary'
                          variant='contained'
                          size='small'
                          startIcon={<FontAwesomeIcon icon='plus' />}
                          onClick={() => arrayHelpers.push(create_coverage())}
                        >
                          Add Coverage
                        </Button>
                      </div>
                    )}
                  />
                </>
              ) : (
                <Typography>
                  Uh oh, it looks like we didn't get your policy document.
                  Please go back and try uploading again.
                </Typography>
              )}
            </CardContent>
            <CardActions>
              <Button
                type='submit'
                disabled={!isValid || isSubmitting}
                variant='contained'
                color='primary'
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
              <Button disabled={isSubmitting} onClick={handleReset}>
                Cancel
              </Button>
            </CardActions>
          </FormikForm>
        )}
      </Formik>
    </Card>
  )
}

EditPolicyForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  policy: PropTypes.object,
  subcontractor: PropTypes.object.isRequired,
  createDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  getDocumentDownloadUrl: PropTypes.func.isRequired,
  removeAdditionalInsuredDocument: PropTypes.func.isRequired,
}

const mapDispatch = {
  createDocument,
  deleteDocument,
  getDocumentDownloadUrl,
  removeAdditionalInsuredDocument,
}

export default connect(null, mapDispatch)(EditPolicyForm)
