import { SET_SUBSCRIPTION } from 'actions/subscription'

const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION:
      return action.subscription
    default:
      return state
  }
}
