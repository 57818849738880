import { API_URL, urls } from 'config/api'
import PropTypes from 'prop-types'
import React from 'react'
import { FilePond } from 'react-filepond'

function TokenUploadFile(props) {
  return (
    <FilePond
      id='w9_upload'
      name='upload'
      maxFileSize='25MB'
      acceptedFileTypes={['application/pdf']}
      server={{
        url: API_URL,
        process: urls.upload(),
        revert: urls.upload(),
        fetch: null,
        headers: {
          Authorization: `token ${props.token}`,
        },
      }}
      onprocessfile={(error, file) => {
        if (!error) {
          props.onUpload(file)
        }
      }}
    />
  )
}

TokenUploadFile.propTypes = {
  token: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
}

export default TokenUploadFile
