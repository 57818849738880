import { compile } from 'path-to-regexp'

export const formatRoute = (route, params = {}) => compile(route)(params)

export default {
  /**
   * Authenticated Routes
   */
  dashboard: '/',
  setup: '/setup',
  profile: '/profile',
  join: '/join',

  // Organizations
  organizationsNew: '/organizations/new',

  // Members
  team: '/team',

  // Billing
  subscription: '/subscription',
  subscriptionNew: '/subscription/new',

  // Subcontractors
  subcontractors: '/subcontractors/',
  subcontractor: '/subcontractors/:subId',
  subcontractorNew: '/subcontractors/new',
  subcontractorEdit: '/subcontractors/:subId/edit',

  // Policies
  policyNew: '/subcontractors/:subId/policy/new',
  policyEdit: '/subcontractors/:subId/policy/:policyId/edit',
  policyUpload: '/subcontractors/:subId/policy/upload',

  /**
   * Subcontractor Token
   */
  upload: '/upload',
  uploadReview: '/upload/review',
  uploadW9: '/upload/w9',
  uploadAgreement: '/upload/agreement',
  uploadPolicy: '/upload/policy',
  uploadPolicyDocument: '/upload/policy/document',

  /**
   * Batch Send Token
   */
  review: '/review',
}
